// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.elevator {
  background-color: #fff;
  position: relative;
  z-index: 2;
  @media @mobile {
    padding: 50px 0;
  }
  @media @desktop-xl {
    padding: 100px 0;
  }

  &__main{
    position: relative;

    &__item{
      position: relative;
      @media @desktop-xl {
        width: 50%;
      }

      & h2{
        @media @mobile {
          text-align: center;
          margin-bottom: 45px;
        }
        @media @desktop-xl {
          text-align: right;
          margin-bottom: 52px;
        }
      }
      .elevator_svg{
        @media @mobile {
          max-width: 500px;
          width: 100%;
          height: ~"calc(-100% / 16 * 9)";
          margin: 0 auto;
          margin-bottom: 30px;
        }
        @media @desktop-xl {
          position: absolute;
          top: 50px;
          right: ~"calc(-100% + 50px)";
          width: 500px;
          height: 280px;
          margin-bottom: 0;
        }
        @media @desktop-xl-1 {
          max-width: 570px;
          width: 100%;
          height: 330px;
        }
        @media @desktop-xl-2 {
          max-width: 590px;
          width: 100%;
          height: 340px;
        }
        & span{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      & ul{
        & li{
          text-transform: uppercase;
          color: rgba(173, 173, 173, 1);
          font-family: 'HelveticaNeueCyr';
          font-weight: 100;
          line-height: 1.5;
          @media @mobile {
            font-size: 20px;
            text-align: center;
          }
          @media @desktop-xl {
            font-size: 24px;
            text-align: right;
          }
          @media @desktop-xl-2 {
            font-size: 30px;
          }
        }
      }
      .arrow_next{
        margin: 0 auto;
        margin-top: 50px;
        @media @desktop-xl {
          float: right;
          margin: 0 auto;
          margin-top: 50px;
          margin: 0;
          margin-top: 50px;
        }
      }
    }
  }
}