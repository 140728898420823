// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.allicons {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  background-color: #fff;
  position: relative;
  z-index: 3;
  @media @mobile {
    padding: 50px 0;
  }
  @media @desktop-xl {
    padding: 100px 0 100px 0;
  }

  &__main{

    .number_sec{
      top: 650px;
    }

    & h2{      
      @media @mobile {
        margin-bottom: 45px;
      }
      @media @desktop-xl {
        margin-bottom: 88px;
      }
    }

    &__item{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      @media @mobile {
        row-gap: 80px;
      }
      @media @tablet {
        row-gap: 110px;
      }

      .allicons_block{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @media @mobile {
          width: 100%;
        }
        @media @tablet {
          width: 50%;
        }
        @media @desktop {
          width: 33.333%;
        }

        .allicons_block_svg{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @media @mobile {
            height: 240px;
          }
          @media @tablet {
            height: 360px;
          }
          & a{
            display: inline-block;
            max-width: 300px;
            width: 100%;
            height: 100%;
            transition: .3s ease-out;
            transform: scale(1);

            &.max-w{
              max-width: 100%;
            }            

            &.allicons_block_svg_bg{
              width: 60%;
              height: 100%;
              
              &.icon_dop_1{
                background: url(../images/icon_dop_1.svg) 50% 50% no-repeat;
                background-size: contain;
              }
              &.icon_dop_2{
                background: url(../images/icon_dop_2.svg) 50% 50% no-repeat;
                background-size: contain;
              }
              &.icon_dop_3{
                background: url(../images/icon_dop_3.svg) 50% 50% no-repeat;
                background-size: contain;
              }
              &.icon_dop_4{
                background: url(../images/icon_dop_4.svg) 50% 50% no-repeat;
                background-size: contain;
              }
              &.icon_dop_5{
                background: url(../images/icon_dop_5.svg) 50% 50% no-repeat;
                background-size: contain;
              }
              &.icon_dop_6{
                background: url(../images/icon_dop_6.svg) 50% 50% no-repeat;
                background-size: contain;
              }
              &.icon_dop_7{
                background: url(../images/icon_dop_7.svg) 50% 50% no-repeat;
                background-size: contain;
                @media @mobile {
                  width: 60%;
                }
                @media @tablet {
                  width: 40%;
                }
              }
            }
          }
        }
        & h3{
          width: 100%;
          @media @mobile {
            margin: 20px 0;
          }
          @media @desktop-xl {
            margin: 45px 0;
          }
          & a{
            display: table;
            text-align: center;
            margin: 0 auto;
            font-size: 20px;
            color: rgba(33, 33, 33, 1);
            font-family: 'HelveticaNeueCyr';
            font-weight: 300;
            text-transform: uppercase;
            transition: all.3s ease-out;
          }
        }
        .only_arrow{
          display: inline-block;
          width: 41px;
          height: 19px;
          margin: 0 auto;
          overflow: hidden;
          position: relative;
          .svg_orange{
            stroke: #f05623;
            transition: 0.4s ease-out;
            position: absolute;
            top: 0;
            left: -55px;
            transition: all.3s ease-out;
          }
          .svg_black{
            stroke: #000000;
            transition: 0.4s ease-out;
            position: absolute;
            top: 0;
            left: 0;
            transition: all.3s ease-out;
          }
        }
        &:hover{
          .allicons_block_svg{
            & a{
              transform: scale(1.05);
            }
          }
          & h3{
            & a{
              color: #f05623;
            }
          }
          .only_arrow{
            .svg_orange{
              left: 0px;
            }
            .svg_black{
              left: 55px;
            }
          }
        }
        
      }
    }
  }
}