// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.objects2 {
  background-color: #ffffff;
  position: relative;
  @media @mobile {
    padding: 50px 0;
  }
  @media @desktop-xl {
    padding: 100px 0;
  }

  &__main{
    @media @mobile {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    @media @desktop-xl {
      justify-content: space-between;
    }
    

    .objects2_cont{
      @media @mobile {
        width: 100%;
        margin-bottom: 45px;
        position: relative;
      }
      @media @desktop-xl {
        width: ~'calc(100% - 620px)';
        margin-bottom: 0;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }
      @media @desktop-xl-1 {
        width: ~'calc(100% - 660px)';
      }
      @media @desktop-xl-2 {
        width: ~'calc(100% - 860px)';
      }
      &.pad_bot{
        @media @desktop-xl {
          padding-bottom: 198px;
        }
        @media @desktop-xl-2 {
          padding-bottom: 173px;
        }
      }
      &.pad_b_45{
        @media @desktop-xl {
          padding-bottom: 45px;
        }
      }

      .objects_sl{
        @media @mobile {
          width: 100%;
          height: auto;
          margin: 0 auto;
          margin-top: 60px;
        }
        @media @desktop-xl {
          height: 360px;
          margin: 0 auto;
          position: sticky;
          top: 250px;
          margin-top: 0;
        }
        &.active{
          & h3{
            opacity: 1;
          }
        }
        .objects_sl_track {
          padding: 20px 0;
        }
        .arr_l{
          @media @mobile {
            right: 40px;
            bottom: -40px;
            transform: none;
            left: auto;
            top: auto;
          }
          @media @desktop-xl {
            right: 40px;
            bottom: -40px;
            transform: none;
            left: auto;
            top: auto;
          }
          @media @desktop-xl-1 {
            right: auto;
            bottom: auto;
            top: 50%;
            left: 90%;
            transform: translateY(-50%);
          }
          @media @desktop-xl-2 {
            left: 100%;
          }
        }
        .arr_r{
          @media @mobile {
            left: auto;
            top: auto;
            right: 0;
            bottom: -40px;
            transform: none;
          }
          @media @desktop-xl {
            right: 0;
            bottom: -40px;
            transform: none;
            left: auto;
            top: auto;
          }
          @media @desktop-xl-1 {
            right: auto;
            bottom: auto;
            top: 50%;
            left: ~'calc(100% - 20px)';
            transform: translateY(-50%);
          }
          @media @desktop-xl-2 {
            left: ~'calc(100% + 40px)';
          }
        }
      }
    }

    &__item{
      display: flex;
      flex-wrap: wrap;
      position: relative;
      @media @mobile {
        width: 100%;
        row-gap: 50px;
      }
      @media @desktop-xl {
        width: 480px;
        row-gap: 100px;
      }
      @media @desktop-xl-1 {
        width: 530px;
      }
      @media @desktop-xl-2 {
        width: 630px;
      }
     .objects2_cont{
      width: 100%;
      @media @desktop-xl {
       left: 560px;
      }
      @media @desktop-xl-1 {
        left: 600px;
        width: 125%;
      }
      @media @desktop-xl-2 {
        left: 700px;
        width: 100%;
      }
      .objects_sl{
        @media @mobile {
          margin-top: 0px;
        }
        @media @desktop-xl {
          margin-top: 60px;
        }
      }
     }

      & h3{
        display: inline-block;
        text-transform: uppercase;
        color: rgba(58, 58, 58, 1);
        font-family: 'HelveticaNeueCyr';
        font-weight: 300;
        margin-bottom: 0;
        @media @mobile {
          font-size: 20px;
          margin-bottom: 30px;
        }
        @media @desktop-xl {
          font-size: 25px;
          margin-bottom: 60px;
        }
      }
      & p{
        width: 100%;
        color: rgba(58, 58, 58, 1);
        font-family: 'HelveticaNeueCyr';
        font-weight: 200;
        text-align: justify;
        line-height: 24.96px;
        @media @mobile {
          font-size: 16px;
          margin-bottom: 30px;
        }
        @media @desktop-xl {
          font-size: 18px;
        }
      }
      .objects_it_cont{
        width: 100%;

        .objects_it_cont_sec{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          row-gap: 60px;

          .objects_it_cont_block{
            width: 100%;
            display: flex;
            align-items: center;
            @media @mobile {
              justify-content: center;
              flex-wrap: wrap;
              row-gap: 30px;
            }
            @media @tablet {
              justify-content: space-between;
              flex-wrap: nowrap;
              column-gap: 50px;
              row-gap: 0;
            }

            .objects_it_cont_block_svg{
              width: 185px;
              flex: 0 0 185px;
              & a{
                display: block;
                width: 100%;
                min-height: 310px;
              }
            }
            .objects_it_cont_block_text{
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-end;
              & p{
                margin-bottom: 30px;
              }
            }
          }
        }
      }
    }
  }
}