// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.peace {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  background-color: #fff;
  position: relative;
  z-index: 2;
  @media @mobile {
    padding: 50px 0;
  }
  @media @desktop-xl {
    padding: 100px 0 50px 0;
    height: 95vh;
  }
  &.peace_div{
    @media @mobile {
      padding: 50px 0;
    }
    @media @desktop-xl {
      padding: 100px 0 100px 0;
      height: auto;
    }
  }

  &__main{
    width: 100%;

    .number_sec{
      @media @desktop-xl {
        left: 50px;
      }
      @media @desktop-xll {
        left: 30px;
      }
    }

    &__item{
      width: 100%;

      & h2{
        width: 100%;
        margin-bottom: 40px;
      }
  
      .peace_text {
        & p{
          color: rgba(91, 91, 91, 1);
          font-family: 'HelveticaNeueCyr';
          font-weight: 200;
          margin-bottom: 20px;
          text-align: justify;
          @media @mobile {
            font-size: 16px;
          }
          @media @desktop-xl {
            font-size: 18px;
          }
        }
      }
    }
  }

  .peace_map{
    background: url(../images/planet.svg) 50% 50% no-repeat;
    background-size: contain;
    @media @mobile {
      width: 100%;
      position: relative;
      margin: 45px auto;
      height: ~"calc(100vh / 16 * 4)";
    }
    @media @tablet {
      width: 75%;
      margin: 0 auto;
      height: ~"calc(100vh / 16 * 9)";
    }
    @media @desktop-xl {
      position: absolute;
      right: -7%;
      bottom: 10vh;
    }
    @media @desktop-xl-1 {
      right: -5%;
      bottom: 7vh;
    }
    @media @desktop-xl-2 {
      bottom: 5vh;
    }
  }
}