// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.generation {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;
  z-index: 2;
  @media @mobile {
    padding: 60px 0 140px 0;
  }
      
  @media @desktop-xl {
    display: block;
    padding: 0;
    height: 100vh;

    & > div {
      padding: 190px 0;
      position: sticky;
      top:0;
      height: 100vh;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__main{
    width: 100%;

    .number_sec_2{
      @media @desktop-xl {
        left: 50px;
      }
      @media @desktop-xll {
        left: 30px;
      }
    }

    .generation_sl{
      position: relative;
      width: 100%;

      .gorizontal-slider-slides {
        @media @mobile {
          width: 100%;
        }          
        @media @tablet {
          max-width: 900px;
        }
      }
      .generation_sl_track{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;

        .generation_sl_block{
          transition: 1s ease-out;

          @media @mobile {
            width: ~"calc(100vw - 60px)" !important;
            flex: 0 0 ~"calc(100vw - 60px)" !important;
            padding: 0;
          }
          @media @desktop {
            width: ~"calc(60vw - 60px)" !important;
            flex: 0 0 ~"calc(60vw - 60px)" !important;
            padding: 0 0 0 5vw;
          } 
          @media @desktop-xl-2 {
            width: 67vw !important;
            flex: 0 0 67vw !important;
            padding: 0 0 0 0;
          }

          & h3{
            display: table;
            font-family: 'HelveticaNeueCyr';
            font-weight: 300;
            color: rgba(215, 215, 215, 1);
            transition: .4s ease-out;
            @media @mobile {
              font-size: 16px;
            }
            @media @tablet {
              font-size: 22px;
            }
            @media @desktop-xl {
              font-size: 24px;
            }
            @media @desktop-xl-1 {
              font-size: 28px;
            }
            @media @desktop-xl-2 {
              font-size: 36px;
            }
            & br{
              @media @mobile {
                display: none;
              }
              @media @tablet {
                display: block;
              }
            }
          }
          &.active{
            & h3{
              font-weight: 300;
              color: rgba(240, 86, 35, 1);
              @media @mobile {
                font-size: 16px;
              }
              @media @tablet {
                font-size: 28px;
              }
              @media @desktop-xl-1 {
                font-size: 32px;
              }
              @media @desktop-xl-2 {
                font-size: 46px;
              }
            }
          }
        }
      }
      .arr_l,
      .arr_r{
        @media @mobile {
          display: block !important;
        }
        @media @desktop-xl {
          display: none !important;
        }
      }
      .arr_l{
        position: absolute;
        bottom: -70px;
        left: ~"calc(100vw - 130px)";
        & svg{
          stroke: #6A6A6A;
        }
        &:hover{
          & svg{
            stroke: #f05623;
          }
        }
      }
      .arr_r{
        position: absolute;
        bottom: -70px;
        left: ~"calc(100vw - 75px)";
        & svg{
          stroke: #6A6A6A;
        }
        &:hover{
          & svg{
            stroke: #f05623;
          }
        }
      }
    }
  }
}