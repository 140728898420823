// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.detals {
  background-color: #fff;
  position: relative;
  z-index: 2;
  overflow: hidden;
  @media @mobile {
    padding: 50px 0;
  }
  @media @desktop-xl {
    padding: 175px 0 100px 0;
  }


  &__main{
    position: relative;

    &__item{
      position: relative;
      @media @mobile {
        width: 100%;
      }
      @media @desktop-xl {
        width: 45%;
      }

      & h2{
        @media @mobile {
          margin-bottom: 45px;
        }
        @media @desktop-xl {
          margin-bottom: 52px;
        }
      }
      .detals_svg{
        @media @mobile {
          max-width: 500px;
          width: 100%;
          height: ~"calc(-100% / 16 * 9)";
          margin: 0 auto;
          margin-bottom: 30px;
        }
        @media @desktop-xl {
          position: absolute;
          top: 0;
          max-width: 640px;
          width: 100%;
          height: 420px;
          right: ~"calc(-100% - 80px)";
        }
        @media @desktop-xl-1 {
          max-width: 690px;
          width: 100%;
          height: 460px;
        }
        @media @desktop-xl-2 {
          max-width: 760px;
          width: 100%;
          height: 550px;
        }
        & span{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      & ul{
        @media @mobile {
          width: fit-content;
          margin: 0 auto;
        }
        @media @desktop-xl {
          width: auto;
        }
        & li{
          & a{
            font-size: 22px;
            color: rgba(173, 173, 173, 1);
            font-family: 'HelveticaNeueCyr';
            font-weight: 200;
            transition: .3s ease-out;
            line-height: 1.8;
            @media @mobile {
              display: table;
              margin: 0 auto;
              font-size: 17px;
            }
            @media @desktop-xl {
              font-size: 18px;
              text-align: left;
              margin: 0;
            }
            @media @desktop-xl-2 {
              font-size: 22px;
            }
            &:before{
              background-color: rgba(240, 86, 35, 1);
            }
            &:hover{
              color: rgba(240, 86, 35, 1);
            }
          }
        }
      }
      .arrow_next{
        @media @mobile {
          margin: 0 auto;
          margin-top: 50px;
        }
        @media @desktop-xl {
          float: right;
        }
      }
    }
  }
}