// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.zernosu {
  background-color: #fff;
  position: relative;
  z-index: 2;
  overflow: hidden;
  @media @mobile {
    padding: 50px 0;
  }
  @media @desktop-xl {
    padding: 100px 0;
  }

  &__main{
    width: 100%;
    position: relative;

    &__item{
      position: relative;
      @media @mobile {
        width: 100%;
      }
      @media @desktop-xl {
        width: 60%;
        min-height: 480px;
        padding-left: 30%;
        float: right;
      }
      @media @desktop-xl-1 {
        min-height: 550px;
      }
      @media @desktop-xl-2 {
        min-height: 700px;
      }

      & h2{
        width: 100%;
        @media @mobile {
          margin-bottom: 45px;
        }
        @media @desktop-xl {
          margin-bottom: 52px;
        }
      }
      .zernosu_svg{
        display: flex;
        align-items: center;
        @media @mobile {
          width: 100%;
          position: relative;
          justify-content: space-between;
        }
        @media @mobile-2 {
          justify-content: center;
        }
        @media @desktop-xl {
          width: 43vw;
          height: 100%;
          top: 0;
          left: -35vw;
          position: absolute;
        }

        .zernosu_svg_block{
          height: 100%;
          @media @mobile {
            width: 45%;
          }
          @media @desktop-xl {
            width: 50%;
          }
          & span{
            display: block;
            width: 100%;
            @media @mobile {
              height: 260px;
            }
            @media @desktop-xl {
              height: 100%;
            }
          }
        }
        .zernosu_text{
          z-index: 1;
          @media @mobile {
            width: 30%;
            position: relative;
          }
          @media @mobile-2-2 {
            width: auto;
          }
          @media @desktop-xl {
            position: absolute;
            right: -170px;
            bottom: 50px;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
          }

          .zernosu_p_1{
            display: inline-block;
            font-family: 'oswald';
            font-weight: 300;
            text-transform: uppercase;
            color: #DCDCDC;
            transition: 1s ease-out;
            @media @mobile {
              font-size: 18px;
              text-align: left;
              line-height: 1.8;
            }
            @media @desktop-xl {
              width: 100%;
              font-size: 20px;
              text-align: right;
              line-height: 1.2;
              margin-bottom: 20px;
              margin-left: 100%;
            }
            @media @desktop-xl-1 {
              font-size: 24px;
            }
            @media @desktop-xl-2 {
              font-size: 30px;
            }
          } 
          .zernosu_p_2{
            display: inline-block;
            font-family: 'oswald';
            font-weight: 300;
            color: #DCDCDC;
            line-height: 1.1;
            transition: 1s ease-out;
            @media @mobile {
              font-size: 18px;
              text-align: left;
              line-height: 1.8;
            }
            @media @desktop-xl {
              width: 100%;
              font-size: 20px;
              text-align: right;
              line-height: 1.2;
              margin-bottom: 20px;
              margin-left: 100%;
            }
            @media @desktop-xl-1 {
              font-size: 54px;
            }
            & br{
              @media @mobile {
                display: none;
              }
              @media @desktop-xl {
                display: block;
              }
            }
            & span{
              @media @mobile {
                font-size: 30px;
                text-align: left;
                line-height: 1.4;
              }
              @media @tablet {
                font-size: 35px;
              }
              @media @desktop-xl {
                font-size: 44px;
                text-align: right;
                line-height: 1.2;
              }
              @media @desktop-xl-1 {
                font-size: 54px;
              }
              @media @desktop-xl-2 {
                font-size: 64px;
              }
            }
          }
          &.anime{
            .zernosu_p_1,
            .zernosu_p_2{
              margin-left: 0;
            }
          }
        }
      }
      & ul{
        @media @mobile {
          width: fit-content;
          margin: 0 auto;
          margin-top: 45px;
        }
        @media @desktop-xl {
          margin-top: 0;
          width: auto;
        }
        & li{
          & a{
            color: rgba(173, 173, 173, 1);
            font-family: 'HelveticaNeueCyr';
            font-weight: 200;
            transition: .3s ease-out;
            line-height: 1.8;
            @media @mobile {
              display: table;
              margin: 0 auto;
              font-size: 17px;
            }
            @media @desktop-xl {
              font-size: 18px;
              margin: 0;
            }
            @media @desktop-xl-2 {
              font-size: 22px;
            }
            &:before{
              background-color: rgba(240, 86, 35, 1);
            }
            &:hover{
              color: rgba(240, 86, 35, 1);
            }
          }
        }
      }
      .arrow_next{
        @media @mobile {
          margin: 0 auto;
          margin-top: 50px;
        }
        @media @desktop-xl {
          position: absolute;
          right: 15px;
          bottom: 140px;
          margin-top: 0;
        }
      }
    }
  }
}