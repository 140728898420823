// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.slider {
  background-color: #fff;
  position: relative;
  z-index: 2;
  @media @mobile {
    padding: 10px 0 50px 0;
  }
  @media @desktop-xl {
    padding: 100px 0;
  }  

  .slider_sl{
    width: 100%;
    position: relative;
    z-index: 2;

    @media @mobile {
      margin-bottom: 45px;
    }
    @media @desktop-xl {
      margin-bottom: 90px;
    }

    .slider_sl_track{
      width: 50000%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      z-index: 1;
      overflow: hidden;

      @media @desktop {
        padding: 30px 0;
      }

      .slider_sl_block{
        transition: .4s ease-out;
        transform: scale(1);
        z-index: 1;
        transform-origin: 0 50%;
        @media @mobile {
          width: 100vw;
          height: 100%;
          object-fit: cover;
        }
        @media @tablet {
          height: auto
        }
        @media @desktop {
          width: 33vw;
          width: auto;
        }

        .slider_sl_block_img{
          position: relative;
          z-index: 1;
          @media @mobile {
            width: auto;
            height: 300px;
          }
          @media @tablet {
            filter: blur(1px);
            height: 360px;
          }
          &:before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(6, 6, 6, 0.6);
            z-index: 2;
            transition: .4s ease-out;
            @media @mobile {
              display: none;
            }
            @media @desktop {
              display: block;
            }
          }
          & img{
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            @media @mobile {
              object-fit: contain;
            }
            @media @desktop {
              object-fit: cover;
            }
          }
        }
        &.active{
          z-index: 2;
          @media @mobile {
            height: auto;
            object-fit: contain;
          }
          @media @desktop {
            height: auto;
            object-fit: contain;
            transform: scale(1.1);
            transform-origin: 0 50%;
          }

          .slider_sl_block_img{
            filter: blur(0);

            &:before{
              background: rgba(6, 6, 6, 0);
            }
          }
        }
      }
    }
    .arr_l{
      position: absolute;
      z-index: 2;
      @media @mobile {
        right: 30px;
        bottom: -140px;
      }
      @media @desktop {
        bottom: 60px;
        right: ~"calc(12% + 50px)";
      }
      & svg{
        @media @mobile {
          stroke: #6A6A6A;
        }
        @media @tablet {
          stroke: #ffffff;
        }
      }
      &:hover{
        & svg{
          stroke: #f05623;
        }
      }
    }
    .arr_r{
      position: absolute;
      bottom: 160px;
      z-index: 2;
      @media @mobile {
        right: 30px;
        bottom: -80px;
      }
      @media @desktop {
        right: 12%;
        bottom: 60px;
      }
      & svg{
        @media @mobile {
          stroke: #6A6A6A;
        }
        @media @tablet {
          stroke: #ffffff;
        }
      }
      &:hover{
        & svg{
          stroke: #f05623;
        }
      }
    }
    .arr_all{
      position: absolute;
      @media @mobile {
        bottom: 0;
        left: 30px;
      }
      @media @tablet {
        bottom: 12px;
        right: 12%;
        left: auto;
      }
    }
  }
  .container{
    position: relative;
    z-index: 1;
  }

  &__main{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 30px;
  }
  .soc_cont{
    @media @mobile-2-2 {
      padding-right: 60px;
    }
    @media @desktop {
      padding-right: 0;
    }
  }
}