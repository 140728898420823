// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.otdels {
  position: relative;
  background-color: #fff;
  @media @mobile {
    padding: 100px 0;
  }
  @media @desktop-xl {
    padding: 150px 0;
  }

  .otdels_bg{
    width: 100%;
    height: 100%;
    background-color: #EAEAEA;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    @media @mobile {
      clip-path: polygon(0 0, 100% 1.5%, 100% 98.5%, 0% 100%);
    }
    @media @desktop-xl {
      clip-path: polygon(0 0, 100% 10%, 100% 90%, 0% 100%);
    }
  }
  .container{
    position: relative;
    z-index: 2;
  }
  &__main{
    width: 100%;
    & h2{
      @media @mobile {
        margin-bottom: 50px;
      }
      @media @desktop-xl {
        margin-bottom: 100px;
      }
    }

    &__item{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      row-gap: 50px;
      column-gap: 30px;
      @media @mobile {
        column-gap: 30px;
        justify-content: center;
      }
      @media @desktop-xl {
        column-gap: 76px;
        justify-content: flex-start;
      }
      @media @desktop-xl-1 {
        column-gap: 86px;
      }
      @media @desktop-xl-2 {
        column-gap: 104px;
      }

      .otdels_block{
        @media @mobile {
          width: 100%;
        }
        @media @tablet {
          width: 45%;
        }
        @media @desktop {
          width: 30%;
        }
        @media @desktop-xl {
          width: 20%;
        }
        & h4{
          display: inline-block;
          text-transform: uppercase;
          color: #3a3a3a;
          font-family: 'HelveticaNeueCyr';
          font-weight: 300;
          margin-bottom: 15px;
          @media @mobile {
            font-size: 18px;
          }
          @media @desktop-xl-2 {
            font-size: 22px;
          }
        }
        & ul{
          & li{
            color: #3a3a3a;
            font-family: 'HelveticaNeueCyr';
            font-weight: 200;
            line-height: 24.96px;
            transition: 0.3s ease-out;
            @media @mobile {
              font-size: 16px;
            }
            @media @desktop-xl-2 {
              font-size: 18px;
            }
            & a{
              color: #3a3a3a;
              font-family: 'HelveticaNeueCyr';
              font-weight: 200;
              line-height: 24.96px;
              transition: 0.3s ease-out;
              @media @mobile {
                font-size: 16px;
              }
              @media @desktop-xl-2 {
                font-size: 18px;
              }
              &:hover{
                color: #f05623;
              }
            }
          }
        }
      }
    }
  }
}