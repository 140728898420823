// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.object {
  background-color: #fff;
  position: relative;
  z-index: 2;
  @media @mobile {
    padding: 50px 0;
  }
  @media @desktop-xl {
    padding: 100px 0;
  }  
  .container{
    position: relative;
    z-index: 2;
  }

  &__main{
    width: 100%;

    & h2{
      @media @mobile {
        margin-bottom: 45px;
      }
      @media @desktop-xl {
        margin-bottom: 88px;
      }
    }
  }
  .object_sl{
    width: 100%;
    overflow: hidden;
    position: relative;
    @media @mobile {
      padding-bottom: 50px;
    }
    @media @tablet {
      padding-top: 20px;
      padding-bottom: 0;
    }
    @media @desktop-xl {
      padding-top: 100px;
    }

    .object_sl_track{
      width: 50000%;
      display: flex;
      justify-content: flex-start;
      position: relative;
      z-index: 1;

      .object_sl_block{
        transition: .3s ease-out;
        z-index: 1;
        @media @mobile {
          width: 100vw;
        }
        @media @tablet {
          width: 33vw;
        }
        @media @desktop {
          width: 25vw;
        }

        .object_sl_block_img{
          transition: .4s ease-out;
          transform: scale(1);
          position: relative;
          z-index: 1;
          filter: blur(1px);
          @media @mobile {
            width: 100vw;
          }
          @media @tablet {
            width: 33vw;
          }
          @media @desktop {
            width: 25vw;
          }
          &:before{
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(6, 6, 6, 0.3);
            z-index: 2;
            transition: .4s ease-out;
          }
          & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            z-index: 1;
          }
        }
        .object_sl_block_text{
          display: inline-block;
          padding-top: 50px;
          clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
          transition: .4s ease-out;
          @media @mobile {
            margin-left: 0px;
          }
          @media @tablet {
            margin-left: 20px;
          }
          & a{
            color: rgba(90, 90, 90, 1);
            font-family: 'HelveticaNeueCyr';
            font-weight: 200;
            transition: .3s ease-out;
            @media @mobile {
              font-size: 17px;
              line-height: 1.4;
            }
            @media @desktop-xl {
              font-size: 20px;
              line-height: 1.2;
            }
            @media @desktop-xl-2 {
              font-size: 23px;
            }
            &:hover{
              color: #f05623;
            }
          }
        }
        &.active{
          z-index: 2;

          .object_sl_block_img{
            box-shadow: 0px 30px 20px -20px rgba(0, 0, 0, 0.44);
            filter: blur(0);
            @media @tablet {
              transform: scale(1.1);
            }

            &:before{
              background: rgba(6, 6, 6, 0);
            }
          }
          .object_sl_block_text{
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            transition-delay: .4s;
            @media @mobile {
              margin-left: 30px;
            }
            @media @tablet {
              margin-left: -20px;
            }
          }
        }
      }
    }
    .arr_l{
      position: absolute;
      z-index: 2;
      @media @mobile {
        right: 30px;
        bottom: 0;
      }
      @media @tablet {
        bottom: 160px;
        right: ~"calc(12% + 50px)";
      }
      & svg{
        @media @mobile {
          stroke: #6A6A6A;
        }
        @media @tablet {
          stroke: #ffffff;
        }
      }
      &:hover{
        & svg{
          stroke: #f05623;
        }
      }
    }
    .arr_r{
      position: absolute;
      bottom: 160px;
      z-index: 2;
      @media @mobile {
        right: 30px;
        bottom: 60px;
      }
      @media @tablet {
        right: 12%;
        bottom: 160px;
      }
      & svg{
        @media @mobile {
          stroke: #6A6A6A;
        }
        @media @tablet {
          stroke: #ffffff;
        }
      }
      &:hover{
        & svg{
          stroke: #f05623;
        }
      }
    }
    .arr_all{
      position: absolute;
      @media @mobile {
        bottom: 0;
        left: 30px;
      }
      @media @tablet {
        bottom: 12px;
        right: 12%;
        left: auto;
      }
    }
  }
  &.object_dop{
    background-color: #ffffff;
    @media @mobile {
      padding: 50px 0 100px 0;
    }
    @media @desktop-xl {
      padding: 100px 0 140px 0;
    }  


    .object__main{
      @media @mobile {
        padding: 0;
      }
      @media @desktop-xl {
        padding: 0 30px 0 0;
      }
      @media @desktop-xl-1 {
        padding: 0 100px 0 0;
      }
      & h2{
        @media @mobile {
          margin: 0 auto;
          margin-bottom: 45px;
        }
        @media @desktop-xl {
          float: right;
        }
      }
    }
  }
  &.object_bg{
    @media @mobile {
      padding: 100px 0 100px 0;
      margin-bottom: 50px;
    }
    @media @desktop-xl {
      padding: 100px 0 140px 0;
      margin-bottom: 0;
    }  

    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #EAEAEA;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      @media @mobile {
        clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);
      }
      @media @desktop-xl {
        clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
      }
    } 

    &::after{
      content: '';
      display: block;
      width: 100%;
      height: ~'calc(100% + 50px)';
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    } 
  }
  &.object_bg_top{
    @media @mobile {
      padding: 100px 0 100px 0;
      margin-bottom: 50px;
    }
    @media @desktop-xl {
      padding: 100px 0 140px 0;
      margin-bottom: 0;
    }  

    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #EAEAEA;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      @media @mobile {
        clip-path: polygon(0 5%, 100% 0, 100% 55%, 0 55%);
      }
      @media @desktop-xl {
        clip-path: polygon(0 10%, 100% 0, 100% 55%, 0 55%);
      }
    } 

    &::after{
      content: '';
      display: block;
      width: 100%;
      height: ~'calc(100% + 50px)';
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    } 
  }
}