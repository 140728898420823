// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.teams {
  background-color: #ffffff;
  position: relative;
  @media @mobile {
    padding: 50px 0 100px 0;
  }
  @media @desktop-xl {
    padding: 100px 0;
  }

  &__main{
    display: flex;
    flex-wrap: wrap;
    @media @mobile {
      row-gap: 50px;
      justify-content: center;
    }
    @media @tablet {
      justify-content: space-between;
    }
    @media @desktop-xl {
      row-gap: 70px;
      padding-left: 100px;
      column-gap: 25px;
    }
    @media @desktop-xl-1 {
      column-gap: 50px;
    }
    @media @desktop-xl-2 {
      column-gap: 70px;
    }

    .product_sec_team_block{
      text-align: center;
      color: rgba(58, 58, 58, 1);
      @media @mobile {
        width: 300px;
      }
      @media @desktop-xl {
        width: 280px;
      }
      @media @desktop-xl-1 {
        width: 300px;
      }
      @media @desktop-xl-2 {
        width: 350px;
      }
      .product_sec_team_img{
        width: 100%;
        @media @mobile {
          height: 380px;
        }
        @media @desktop-xl {
          height: 350px;
        }
        @media @desktop-xl-1 {
          height: 400px;
        }
        @media @desktop-xl-2 {
          height: 450px;
        }
        margin-bottom: 30px;
        & img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      & h4{
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
      }
      .polo{
        width: 100%;
        height: 1px;
        margin: 10px 0;
        background-color: #E4E4E4;
      }
      & p{
        font-size: 20px;
        font-weight: 200;
      }
    }
  }
  
}