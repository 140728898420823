// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }


.lottie {
  background-color: transparent;
  width:100%;
  height:100%;
  display:block;
  overflow: hidden;
  transform: translate3d(0,0,0);
  text-align: center;
  opacity: 1;
}
.lazy-frame-parent.no-background,
.lazy-frame-parent.no-background .hide-background-on-hover {
  background: none !important;
}
.lottie.onhover {
  opacity: 1;
}
.lottie.onhover.noopacity {
  opacity: 1 !important;
}
.lazy-frame-parent.no-background .lottie.onhover,
.lottie.onhover:hover {
  opacity: 1;
}

html {
  overflow-x: hidden;
}

body {
  width: 100%;
  background-color: #fff;
  position: relative;

  @media @mobile {
    overflow-x: hidden !important;
  }
  @media @desktop-xl {
    overflow: visible !important;
  }
}

.container {
  position: relative;

  @media @mobile {
    width: 100%;
    padding: 0 30px;
  }

  @media @tablet {
    padding: 0 15px;
  }

  @media @desktop {
    width: 945px;
  }

  @media @desktop-xl {
    max-width: 1200px;
    width: 100%;
  }

  @media @desktop-xl-1 {
    max-width: 1366px;
    width: 100%;
  }

  @media @desktop-xl-2 {
    max-width: 1600px;
    width: 100%;
  }
}

// =================>

.only_arrow{
  display: inline-block;
  width: 41px;
  height: 19px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  .svg_orange{
    stroke: #f05623;
    transition: 0.4s ease-out;
    position: absolute;
    top: 0;
    left: -55px;
    transition: all.3s ease-out;
  }
  .svg_black{
    stroke: #000000;
    transition: 0.4s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    transition: all.3s ease-out;
  }
  &:hover{
    .svg_orange{
      left: 0px;
    }
    .svg_black{
      left: 55px;
    }
  }
}

// =================>

.loopa{
  display: block;
  width: 38px;
  height: 38px;
  background: url(../images/loopa.svg) 50% 50% no-repeat;
  background-size: contain;
}
.download{
  display: block;
  width: 31px;
  height: 30px;
  background: url(../images/download.svg) 50% 50% no-repeat;
  background-size: contain;
}

// =================>

.top_menu{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  @media @mobile {
    padding-top: 30px;
  }
  @media @tablet {
    padding-top: 20px;
  }

  &__main{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top_menu_logo{
      display: flex;
      justify-content: flex-start;

      .logo_kmz{
        display: block;
        position: relative;
        background: rgba(241, 86, 35, 1);
        @media @mobile {
          width: 42px;
          height: 30px;
        }
        @media @tablet {
          width: 57px;
          height: 39px;
        }
        &:before{
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: url(../images/logo_kmz.svg) 50% 50% no-repeat;
          background-size: contain;
          @media @mobile {
            width: 30px;
            height: 16px;
          }
          @media @tablet {
            width: 42px;
            height: 20px;
          }
        }
      }
      .logo_vab{
        display: block;
        background: url(../images/logo_vab.svg) 50% 50% no-repeat;
        background-size: contain;
        @media @mobile {
          width: 30px;
          height: 30px;
          margin-left: 10px;
        }
        @media @tablet {
          width: 44px;
          height: 34px;
          margin-left: 20px;
        }
      }
    }

    .top_menu_us{
      display: flex;
      justify-content: space-between;
      align-items: center;    
      @media @mobile {
        width: 80px;
      }
      @media @mobile-2-2 {
        width: 315px;
      }   
      @media @tablet {
        width: 420px;
      }   
      @media @desktop-xl {
        width: 45%;
      }
      @media @desktop-xl-1 {
        width: 35%;
      }

      .poisk{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        @media @mobile-2-2 {
          padding-right: 15px;
        }
        @media @tablet {
          padding-right: 0;
        }
        & input{
          margin: 0;
          clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
          transition: .3s ease-out;
          @media @mobile {
            width: 260px;
            position: absolute;
            right: 0;
            bottom: -50px;
          }
          @media @mobile-2-2 {
            bottom: auto;
            position: relative;
          }
        }
        .block_ic{
          width: 22px;
          height: 22px;
          cursor: pointer;
          margin-left: 10px;

          .block_ic_ic{
            width: 22px;
            height: 22px;
            background: url(../images/poisk.svg) 50% 50% no-repeat;
            background-size: contain;
            transition: .3s ease-out;
            transform: rotate(0);
            cursor: pointer;
            &:hover{
              @media @desktop-xl {
                transform: rotate(90deg);
              }
            }
          }
        }
        &.active{
          & input{
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
          }
          .block_ic{
            .block_ic_ic{
              transform: rotate(90deg);
            }
          }
        }
      }
      
      .block_v{
        width: 55px;
        height: 20px;
        border-radius: 24px;
        padding: 0 18px 0 10px;
        position: relative;
        cursor: pointer;
        @media @mobile {
          display: none;
        }
        @media @tablet {
          display: block;
        }
        &:before,
        .block_arrow {
          display: block;
          content: '';
          width: 12px;
          height: 20px;
          padding: 7px 0;
          position: absolute;
          top: 0;
          right: 0;
          background: url(../images/arrow_v.svg) 50% 50% no-repeat;
          background-size: contain;
          transition: .3s ease-out;
          transform: rotate(0);
        }
        &:after{
          content: '';
          display: block;
          width: 200vw;
          height: 0;
          background-color: #373737;
          position: absolute;
          top: 35px;
          left: -100vw;
          transition: .3s ease-out;
          z-index: -1;
        }
        .block_v_cont{
          & p{
            font-size: 17px;
            color: #fff;
            font-family: 'HelveticaNeueCyr';
            font-weight: 300;
            &:before{
              background-color: #fff;
            }
          }
        }
        .block_v_s{
          width: 100%;
          position: absolute;
          top: 24px;
          left: 0;
          transition: .3s ease-out;
          clip-path: polygon(100% 0, 0 0, 0 0, 100% 0);
          z-index: 0;
          padding-left: 10px;
          & a{
            font-size: 17px;
            color: #fff;
            font-family: 'HelveticaNeueCyr';
            font-weight: 400;
            display: table;
            position: relative;
            &:before{
              background-color: #fff;
            }
          }
        }
        &:hover{
          &:before,
          .block_arrow{
            top: 3px;
          }
        }
        &.active{
          &:before,
          .block_arrow{
            transform: rotate(-180deg);
          }
          .block_v_s{
            clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
          }
          &:hover{
            &:before,
            .block_arrow{
              top: 0;
            }
          }
        }
      }
    }
  }
}

// =================>

.icon_dop_1{
  background: url(../images/icon_dop_1.svg) 50% 50% no-repeat;
  background-size: contain;
}
.icon_dop_2{
  background: url(../images/icon_dop_2.svg) 50% 50% no-repeat;
  background-size: contain;
}
.icon_dop_3{
  background: url(../images/icon_dop_3.svg) 50% 50% no-repeat;
  background-size: contain;
}
.icon_dop_4{
  background: url(../images/icon_dop_4.svg) 50% 50% no-repeat;
  background-size: contain;
}
.icon_dop_5{
  background: url(../images/icon_dop_5.svg) 50% 50% no-repeat;
  background-size: contain;
}
.icon_dop_6{
  background: url(../images/icon_dop_6.svg) 50% 50% no-repeat;
  background-size: contain;
}
.icon_dop_7{
  background: url(../images/icon_dop_7.svg) 50% 50% no-repeat;
  background-size: contain;
  @media @mobile {
    width: 60%;
  }
  @media @tablet {
    width: 40%;
  }
}

// =================>

.objects_sl{
  @media @mobile {
    width: 100%;
  }
  @media @desktop-xl {
    width: 70%;
    position: absolute;
    top: 0;
    left: 50%;
  }

  .custom-slider-slides{
    padding: 0;
  }
  .objects_sl_track{
    width: 50000%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    @media @mobile {
      margin-left: -30px;
    }
    @media @mobile-2-2 {
      margin-left: 0;
    }

    @media @desktop {
      padding: 30px 0;
    }

    .objects_sl_block{
      transition: .4s ease-out;
      transform: scale(1);
      z-index: 1;
      transform-origin: 0 50%;
      @media @mobile {
        width: 100vw;
        height: 100%;
        object-fit: cover;
        opacity: 0;
      }
      @media @mobile-2-2 {
        width: ~'calc(100vw - 70px)';
      }
      @media @tablet {
        height: auto
      }
      @media @desktop {
        width: 60%;
        width: auto;
        opacity: 1;
      }

      .objects_sl_block_img{
        position: relative;
        z-index: 1;
        @media @mobile {
          width: auto;
          height: auto;
        }
        @media @mobile-2-2 {
          height: 300px;
        }
        @media @tablet {
          filter: blur(1px);
        }
        &:before{
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(6, 6, 6, 0.6);
          z-index: 2;
          transition: .4s ease-out;
          @media @mobile {
            display: none;
          }
          @media @desktop {
            display: block;
          }
        }
        & img{
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 1;
          @media @mobile {
            object-fit: contain;
          }
          @media @desktop {
            object-fit: cover;
          }
        }
      }
      &.active{
        z-index: 2;
        @media @mobile {
          height: auto;
          object-fit: contain;
          opacity: 1;
        }
        @media @desktop {
          height: auto;
          object-fit: contain;
          transform: scale(1.1);
          transform-origin: 0 50%;
        }    
        .objects_sl_block_img{
          filter: blur(0);

          &:before{
            background: rgba(6, 6, 6, 0);
          }
        }
      }
    }
  }
  .arr_l{
    position: absolute;
    z-index: 2;
    @media @mobile {
      right: 40px;
      bottom: 0;
    }
    @media @desktop {
      right: auto;
      bottom: auto;
      top: 50%;
      left: 65%;
      transform: translateY(-50%);
    }
    & svg{
      @media @mobile {
        stroke: #6A6A6A;
      }
      @media @tablet {
        stroke: #ffffff;
      }
    }
    &:hover{
      & svg{
        stroke: #f05623;
      }
    }
  }
  .arr_r{
    position: absolute;
    bottom: 160px;
    z-index: 2;
    @media @mobile {
      right: 0px;
      bottom: 0;
    }
    @media @desktop {
      right: auto;
      bottom: auto;
      top: 50%;
      left: ~'calc(65% + 40px)';
      transform: translateY(-50%);
    }
    & svg{
      @media @mobile {
        stroke: #6A6A6A;
      }
      @media @tablet {
        stroke: #ffffff;
      }
    }
    &:hover{
      & svg{
        stroke: #f05623;
      }
    }
  }
  .arr_all{
    position: absolute;
    @media @mobile {
      bottom: 0;
      left: 30px;
    }
    @media @tablet {
      bottom: 12px;
      right: 12%;
      left: auto;
    }
  }
}

// =================>

.soc_cont{
  float: right;
  display: flex;
  @media @mobile {
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 30px;
  }
  @media @tablet {
    justify-content: space-between;
    row-gap: 0;
  }
  @media @desktop-xl {
    max-width: 860px;
    width: 100%;
    flex-wrap: wrap;
  }

  .soc_cont_text{
    font-size: 13px;
    font-weight: 300;
    color: rgba(107, 107, 107, 1);
    @media @mobile {
      width: 100%;
      text-align: left;
      margin-bottom: 10px;
    }
    @media @tablet {
      width: auto;
      text-align: right;
      margin-bottom: 0;
    }
  }

  .soc_cont_main{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media @mobile {
      width: 100%;
      flex-wrap: wrap;
    }
    @media @tablet {
      width: auto;
      flex-wrap: nowrap;
    }

    .soc_cont_ic{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media @mobile {
        column-gap: 20px;
      }
      @media @tablet {
        column-gap: 15px;
        margin-left: 25px;
      }
    }
  }
}

// =================>

.block_shadow_1{
  position: absolute;
  background: url(../images/shadow.png) 50% 50% no-repeat;
  background-size: contain;
  color: transparent;
  opacity: .3;
  @media @mobile {
    width: 300px;
    height: 300px;
    right: -150px;
    top: 500px;
    animation: block_shadow_1_mob 5s ease-in-out infinite;
  }
  @media @desktop-xl-2 {
    width: 700px;
    height: 700px;
    right: -400px;
    top: 1000px;
    animation: block_shadow_1 5s ease-in-out infinite;
  }
  @keyframes block_shadow_1_mob {
    0% {
      width: 300px;
      height: 300px;
    }
    50% {
      width: 200px;
      height: 200px;
    }
    100% {
      width: 300px;
      height: 300px;
    }
  }
  @keyframes block_shadow_1 {
    0% {
      width: 700px;
      height: 700px;
    }
    50% {
      width: 500px;
      height: 500px;
    }
    100% {
      width: 700px;
      height: 700px;
    }
  }
}
.block_shadow_2{
  position: absolute;
  background: url(../images/shadow_2.png) 50% 50% no-repeat;
  background-size: contain;
  color: transparent;
  opacity: .3;
  @media @mobile {
    width: 300px;
    height: 300px;
    left: -150px;
    bottom: -100px;
    animation: block_shadow_1_mob 5s ease-in-out infinite;
  }
  @media @desktop-xl {
    width: 700px;
    height: 700px;
    left: -400px;
    bottom: -200px;
    animation: block_shadow_1 5s ease-in-out infinite;
  }
}
.block_shadow_3{
  position: absolute;
  background: url(../images/shadow.png) 50% 50% no-repeat;
  background-size: contain;
  left: 50%;
  transform: translateX(-50%) rotate(-90deg);
  color: transparent;
  opacity: .2;
  z-index: 1;
  @media @mobile {
    width: 500px;
    height: 500px;
    bottom: -200px;
    animation: block_shadow_3_mob 5s ease-in-out infinite;
  }
  @media @desktop-xl {
    width: 1200px;
    height: 1200px;
    bottom: -400px;
    animation: block_shadow_3 5s ease-in-out infinite;
  }
  @keyframes block_shadow_3_mob {
    0% {
      width: 500px;
      height: 500px;
    }
    50% {
      width: 350px;
      height: 350px;
    }
    100% {
      width: 500px;
      height: 500px;
    }
  }
  @keyframes block_shadow_3 {
    0% {
      width: 1200px;
      height: 1200px;
    }
    50% {
      width: 900px;
      height: 900px;
    }
    100% {
      width: 1200px;
      height: 1200px;
    }
  }
}
.block_shadow_4{
  position: absolute;
  background: url(../images/shadow.png) 50% 50% no-repeat;
  background-size: contain;
  color: transparent;
  opacity: .3;
  @media @mobile {
    width: 300px;
    height: 300px;
    right: -100px;
    top: -100px;
    animation: block_shadow_1_mob 5s ease-in-out infinite;
  }
  @media @desktop-xl {
    width: 700px;
    height: 700px;
    right: -250px;
    top: -250px;
    animation: block_shadow_1 5s ease-in-out infinite;
  }
}

.block_shadow_5{
  position: absolute;
  background: url(../images/shadow.png) 50% 50% no-repeat;
  background-size: contain;
  transform: rotate(0);
  color: transparent;
  opacity: .2;
  z-index: 1;
  @media @mobile {
    width: 500px;
    height: 500px;
    right: -400px;
    bottom: -400px;
    animation: block_shadow_3_mob 5s ease-in-out infinite;
  }
  @media @desktop-xl {
    width: 1200px;
    height: 1200px;
    right: -600px;
    bottom: -600px;
    animation: block_shadow_3 5s ease-in-out infinite;
  }
}

// =================>

.error_head {
  height: 100vh !important;
  margin: 0 !important;
  .container{
    padding: 0 !important;
  }
}
.error_404 {
  display: table;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center !important;
  color: #fff;
  z-index: 5;
}

// =================>

&::placeholder {
  font-size: 16px;
  color: #828385;
  font-weight: 300;
}

p {
  font-size: 22px;
  color: rgba(90, 90, 90, 1);
  margin: 0;
  line-height: 1.2;
  font-family: 'HelveticaNeueCyr';
}

h1 {
  margin: 0;
  line-height: 1.2;
}

h2 {
  text-transform: uppercase;
  line-height: 1.2;
  font-family: 'HelveticaNeueCyr';
  font-weight: 300;
  margin: 0;
  color: rgba(49, 49, 49, 1);

  @media @mobile {
    font-size: 24px;
    text-align: center;
  }
  @media @desktop-xl {
    font-size: 40px;
    text-align: left;
  }
  @media @desktop-xll {
    font-size: 50px;
  }
}
.h2_anime{
  position: relative;
  transition: .75s ease-out;
  clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
  z-index: 3;

  @media @mobile {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  @media @desktop-xl {
    text-align: left;
    float: left;
    margin: 0;
  }
  
  &:before{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #F8F6F3;
    mix-blend-mode: exclusion;
    transition: .75s ease-in;
    transition-delay: .75s;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  
  &.scrolled{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  
    &:before{
      clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
    }
  }
  &.h2_anime_no{
    &:before{
      display: none;
    }
  }
}

h3 {
  line-height: 1.2;
  font-family: 'HelveticaNeueCyr';
  margin: 0;
}
h4 {
  line-height: 1.2;
  font-family: 'HelveticaNeueCyr';
  margin: 0;
}

h5 {
  line-height: 1.2;
  font-family: 'HelveticaNeueCyr';
  margin: 0;
}

a {
  outline: none;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}
.pad_l{
  @media @desktop-xl {
    padding-left: 75px;
  }
  @media @desktop-xl-2 {
    padding-left: 150px;
  }
}
// =================>

.ic{
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  margin-bottom: 0;
  transition: .3s ease-out;
  &:before{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    filter: blur(2px);
    transform: rotate3d(-1, 0, 0, 90deg);
    transform-origin: bottom;
    transition: .3s ease-out;
  }
  &.sms{
    display: block;
    background: url(../images/sms.svg) 50% 50% no-repeat;
    background-size: contain;
    position: fixed;
    right: 15px;
    top: 41%;
    transform: translateY(-50%);
    z-index: 6;
    @media @mobile {
      width: 30px;
      height: 24px;
    }
    @media @tablet {
      width: 39px;
      height: 29px;
    }
    @media @desktop-xl {
      &:before{
        background: url(../images/sms.svg) 50% 50% no-repeat;
        background-size: contain;
      }
      &:hover{
        top: 40.5%;
        &:before{
          opacity: 1;
          transform: rotate3d(-1, 0, 0, 120deg);
          top: 4px;
        }
      }
    }
  }
  &.ic_fb{
    background: url(../images/ic_fb.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/ic_fb.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &.ic_tw{
    background: url(../images/ic_tw.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/ic_tw.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &.ic_in{
    background: url(../images/ic_in.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/ic_in.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }  
  &.ic_wp{
    background: url(../images/ic_wp.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/ic_wp.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &.ic_te{
    background: url(../images/ic_te.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/ic_te.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &.ic_mr{
    background: url(../images/ic_mr.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/ic_mr.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &.ic_sk{
    background: url(../images/ic_sk.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/ic_sk.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &.ic_ma{
    background: url(../images/ic_ma.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/ic_ma.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &:hover{
    @media @desktop-xl {
      margin-bottom: 6px;
      &:before{
        opacity: 1;
        transform: rotate3d(-1, 0, 0, 120deg);
        top: 4px;
      }
    }
  }
}

.ic_f{
  display: block;
  width: 36px;
  height: 36px;
  position: relative;
  margin-bottom: 0;
  transition: .3s ease-out;
  &:before{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    filter: blur(2px);
    transform: rotate3d(-1, 0, 0, 90deg);
    transform-origin: bottom;
    transition: .3s ease-out;
  }
  &.ic_phone{
    background: url(../images/phone.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/phone.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &.ic_mail{
    background: url(../images/mail.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/mail.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &.ic_pointer{
    background: url(../images/pointer.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/pointer.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &.ic_in2{
    background: url(../images/in.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/in.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &.ic_fac{
    background: url(../images/facebook.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/facebook.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &.ic_you{
    background: url(../images/youtube.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/youtube.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &.ic_ins{
    background: url(../images/instagram.svg) 50% 50% no-repeat;
    background-size: contain;
    &:before{
      background: url(../images/instagram.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &:hover{
    @media @desktop-xl {
      margin-bottom: 6px;
      &:before{
        opacity: 1;
        transform: rotate3d(-1, 0, 0, 120deg);
        top: 4px;
      }
    }
  }
}

// =================>

.bg_sec_2{
  width: 100%;
  position: relative;
  z-index: 2;
  background-color: #fff;
  @media @mobile {
    height: 240px;
  }
  @media @tablet {
    height: 350px;
  }
  @media @desktop-xl {
    height: 420px;
  }
  @media @desktop-xl-2 {
    height: 522px;
  }
  .bg_sec_cont{
    width: 100%;
    height: 100%;
    position: relative;
    clip-path: polygon(0 0, 100% 25%, 100% 100%, 0% 100%);
  }
}
.bg_sec_5{
  width: 100%;
  background-color: #fff;
  position: relative;
  z-index: 2;
  @media @mobile {
    height: 240px;
  }
  @media @tablet {
    height: 350px;
  }
  @media @desktop-xl {
    height: 420px;
  }
  @media @desktop-xl-2 {
    height: 600px;
  }
  .bg_sec_cont{
    width: 100%;
    height: 100%;
    position: relative;
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
  }
  &.obj_3{
    position: relative;
    z-index: 3;
    padding-bottom: 60px;
    &:before{
      content: '';
      width: 100%;
      height: 50%;
      background-color: #EAEAEA;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      @media @mobile {
        display: none;
      }
      @media @desktop-xl {
        display: block;
      }
    }
    @media @mobile {
      padding-bottom: 60px;
    }
    @media @desktop-xl {
      padding-bottom: 0;
      margin-bottom: -100px;
    }
    .bg_sec_cont{
      width: 100%;
      height: 100%;
      position: relative;
      clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 85%);
    }
  }
}
#box1{
  width: 100%;
  position: fixed;
  @media @mobile {
    height: 100vh;
    top: 0;
    object-fit: cover;
  }
}
#box2{
  width: 100%;
  display: block;
  position: fixed;
  @media @mobile {
    height: 100vh;
    object-fit: cover;
    top: 0;
  }
  @media @tablet {
    top: 0;
  }
  @media @desktop {
    top: 0;
    height: auto;
  }
  @media @desktop-xl-2 {
    top: 0;
  }
}

// =================>

.arrow_next{
  display: block;
  background: url(../images/arrow_black.svg) 50% 50% no-repeat;
  background-size: contain;
  position: relative;
  transition: .5s ease-out;
  transform: rotate3d(0, 0, 0, 0deg);
  @media @mobile {
    width: 60px;
    height: 60px;
  }
  @media @desktop-xl-2 {
    width: 80px;
    height: 80px;
  }
  &:before{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url(../images/arrow_black.svg) 50% 50% no-repeat;
    background-size: contain;
    position: absolute;
    top: 4px;
    left: 0;
    transform-origin: bottom;
    filter: blur(4px);
    transform: rotate3d(-1, 0, 0, 100deg) scale(.9);
    opacity: 0;
    transition: .5s ease-out;
  }
  &:hover{
    transform: rotate3d(0, 1, 0, 30deg);
    &:before{
      opacity: .7;
      transform: rotate3d(-1, 0.1, 0, 100deg) scale(.9);
    }
  }
}

.arrow_next_white{
  display: block;
  background: url(../images/arrow_white.svg) 50% 50% no-repeat;
  background-size: contain;
  position: relative;
  margin-bottom: 0;
  transition: .5s ease-out;
  transform: rotate3d(0, 0, 0, 0deg);
  @media @mobile {
    width: 60px;
    height: 60px;
  }
  @media @desktop-xl-2 {
    width: 80px;
    height: 80px;
  }
  &:before{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url(../images/arrow_white.svg) 50% 50% no-repeat;
    background-size: contain;
    position: absolute;
    top: 4px;
    left: 0;
    transform-origin: bottom;
    filter: blur(4px);
    transform: rotate3d(-1, 0, 0, 100deg) scale(.8);
    opacity: 0;
    transition: .5s ease-out;
  }
  &:hover{
    transform: rotate3d(0, 1, 0, 30deg);
    &:before{
      top: 4px;
      opacity: .7;
      transform: rotate3d(-1, 0.1, 0, 100deg) scale(.8);
    }
  }
}

// =================>

.arr_all{
  display: table;
  font-size: 17px;
  font-weight: 300;
  color: rgba(240, 86, 35, 1);
  z-index: 3;
  padding-right: 40px;
  transition: .4s ease-out;
  position: relative;
  &:before{
    content: '';
    display: block;
    width: 27px;
    height: 9px;
    background: url(../images/arrow_text.svg) 50% 50% no-repeat;
    background-size: contain;
    position: absolute;
    top: 52%;
    right: 0;
    transform: translateY(-50%);
    transition: .4s ease-out;
  }
  &:hover{
    padding-right: 50px;
    &:before{
      right: -10px;
    }
  }
  &.arr_all_l{
    padding-left: 40px;
    &:before{
      content: '';
      display: block;
      width: 27px;
      height: 9px;
      background: url(../images/arrow_text.svg) 50% 50% no-repeat;
      background-size: contain;
      position: absolute;
      top: 52%;
      left: 0;
      transform: translateY(-50%) rotate(-180deg);
      transition: .4s ease-out;
    } 
    &:hover{
      padding-left: 50px;
      &:before{
        left: -10px;
      }
    }   
  }
}

.arr_l,
.arr_r{
  width: 26px;
  height: 48px;
  cursor: pointer;
  & svg{
    width: 100%;
    height: 100%;
    stroke: #ffffff;
    transition: .3s ease-out;
  }
  &:hover{
    & svg{
      stroke: #f05623;
    }
  }
  &.bg_white{
    &:hover{
      & svg{
        stroke: #f05623;
      }
    }
  }
  &.black{
    & svg{
      stroke: rgba(106, 106, 106, 1) !important;
    }
    &:hover{
      & svg{
        stroke: #f05623 !important;
      }
    }
  }
}
.arr_r{
  & svg{
    transform: rotate(180deg);
  }
}

// =================>

.number_sec{
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: 0% 100%;
  display: flex;
  align-items: center;
  z-index: 5;
  @media @mobile {
    display: none;
  }
  @media @desktop-xl {
    width: 400px;
    top: 350px;
    left: 35px;
    display: flex;
  }
  @media @desktop-xl-1 {
    width: 460px;
    top: 420px;
  }
  @media @desktop-xl-2 {
    width: 530px;
    top: 500px;
  }
  @media @desktop-xll {
    left: 15px;
  }
  &.min_1_3{
    width: 400px;
    top: 350px !important;
  }

  .number_sec_p{
    flex: 0 0 auto;
    margin-right: 30px;
    min-width: 100px;
    min-height: 20px;
    position: relative;
    overflow: hidden;

    .number_sec_text{
      text-transform: uppercase;
      font-size: 15px;
      color: rgba(174, 174, 174, 1);
      font-family: 'HelveticaNeueCyr';
      font-weight: 200;
      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    }
  }
  .number_sec_polo{
    width: 0;
    height: 1px;
    background-color: rgba(174, 174, 174, 1);
  }
  &.number_sec_2{
    top: 50%;
    transform: rotate(-90deg) translateX(-50%);

    &:before{
      content: '';
      display: block;
      width: ~"calc(100% + 20px)";
      height: ~"calc(100vh + 20px)";
      background-image: linear-gradient(0deg, transparent 0%, #ffffff 15%);
      position: absolute;
      left: 50%;
      bottom: -130px;
      transform: translateX(-50%);
      z-index: -1;
    }
  }
  &.scrolled{
    .number_sec_text{
      animation: number_sec_text 1s ease-out;
      animation-fill-mode: forwards;
    }
    @keyframes number_sec_text {
      0% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
      }
      100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
    .number_sec_polo{
      animation: number_sec_polo 1s ease-out;
      animation-fill-mode: forwards; 
    }
    @keyframes number_sec_polo {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
}

// =================>

.btn_orange {
  display: flex;
  width: auto;
  padding: 0 30px;
  height: 45px;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #F05623;
  border: none;
  outline: none;
  color: #fff;
  font-size: 16px;
  font-family: 'HelveticaNeueCyr';
  font-weight: 200;
  text-transform: uppercase;
  transition: .3s ease-in-out;
  position: relative;
  letter-spacing: 1px;
  & span{
    z-index: 2;
    position: relative;
    display: table;
    &:before{
      background: #fff;
    }
    &:hover{
      &:before{
        background: #fff;
      }
    }
  }
  &:hover{
    & span{
      &:before{
        background: #fff;
      }
    }
  }
}

// =================>

.a_hover {
  position: relative;
  transition: 0s;
  transition-delay: 0;
  transition: transform 0.2s ease-out;

  &:before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    background: #f05623;
    position: absolute;
    left: -8px;
    top: 0;
    transition: .4s ease-out;
    opacity: 0;
  }

  &:hover,
  &.active {
    &:before {
      opacity: 1;
      background: #f05623;
      @media @mobile {
        top: calc(100% - 40px);
      }
      @media @desktop-xl {
        top: calc(100% - 46px);
      }
    }
  }
}

// =================>

// menu

.menu {
  position: fixed;
  right: -100%;
  top: 0px;
  height: 100%;
  z-index: 1001;
  transition: all 0.3s cubic-bezier(.77, .06, .28, .85);
  overflow: hidden;
  @media @mobile {
    width: ~"calc(100% - 30px)";
    padding-left: 30px;
    background: #F1F1F1;
  }
  @media @tablet {
    width: 100%;
    padding-left: 0;
    background: #ffffff;
  }
}


#menu-trigger {
  width: 24px;
  height: 14px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  z-index: 99;
  transition: all 0.3s cubic-bezier(.77, .06, .28, .85);
  z-index: 1100;
  &.opened{
    position: fixed;
    top: 15px;
    right: 5px;
    top: 30px;
    right: -45px;
    opacity: 0;
  }
}

#menu-trigger.clicked{
  &.opened{
    opacity: 1;
    @media @mobile {
      top: 30px;
      right: 30px;
    }
  }
}

#menu-trigger div {
  position: relative;
  height: 2px;
  width: 30px;
  transition: all 0.3s ease;
  display: block;
  z-index: 99;
  background-color: #fff;
  border-radius: 2px;
}

#menu-trigger:hover {
  cursor: pointer;
}

#menu-trigger.clicked div:nth-child(even) {
  opacity: 0;
}

#menu-trigger.clicked div:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(48deg);
  top: 0;
  left: 0px;
  background-color: rgba(172, 172, 172, 1);
}

#menu-trigger.clicked div:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: -12px;
  right: 0;
  background-color: rgba(172, 172, 172, 1);
}

.menu.open {
  right: 0;
}

.table {
  display: flex;
  height: 100%;
  width: 100%;
  @media @mobile {
    justify-content: flex-end;
  }
  @media @desktop-xl {
    justify-content: space-between;
  }
  .menu_img{
    width: 25%;
    height: 100%;
    @media @mobile {
      display: none;
    }
    @media @desktop-xl {
      display: block;
    }
    & img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .menu_cont{
    display: flex;
    justify-content: space-between;
    @media @mobile {
      width: 100%;
      flex-wrap: wrap;
      align-content: center;
    }
    @media @tablet {
      width: 75%;
      flex-wrap: nowrap;
    }
    @media @desktop-xl {
      padding: 30px;
    }
    @media @desktop-xl-1 {
      padding: 60px;
    }
    @media @desktop-xl-2 {
      padding: 80px;
    }
    @media @desktop-xll {
      padding: 120px;
    }

    .footer_cont{
      display: flex;

      &.footer_cont_1{
        @media @mobile {
          width: 100%;
        }
        @media @tablet {
          width: 220px;
        }
      }
      &.footer_cont_2{
        @media @mobile {
          width: 100%;
        }
        @media @tablet {
          width: 320px;
        }
      }
      &.footer_cont_3{
        @media @mobile {
          width: 100%;
        }
        @media @tablet {
          width: 340px;
        }
      }
      .poldl{
        width: 100%;
        margin-bottom: 15px;
        &.marg_15{
          margin-bottom: 10px;
        }
        &.marg_30{
          @media @mobile {
            margin-bottom: 10px;
          }
          @media @tablet {
            margin-bottom: 20px;
          }
          @media @desktop-xl-1 {
            margin-bottom: 30px;
          }
        }
        &.marg_30_top{
          @media @mobile {
            margin-bottom: 10px;
          }
          @media @tablet {
            margin-bottom: 20px;
          }
          @media @desktop-xl-1 {
            margin-bottom: 30px;
          }
        }
        &.marg_15_mob{
          @media @mobile {
            margin-bottom: 10px;
          }
          @media @tablet {
            margin-top: 30px;
          }
        }
        &.marg_30_mob{
          
          @media @mobile {
            margin: 30px 0;
          }
          @media @tablet {
            margin: 0;
            margin-bottom: 20px;
          }
          @media @desktop-xl-1 {
            margin-bottom: 30px;
          }
        }
      }
      & h3,
      .a_h3{
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 300;
        font-family: 'HelveticaNeueCyr';
        color:rgba(24, 24, 24, 1);
        &.zag_min_mob{
          @media @mobile {
            font-size: 16px;
            text-transform: none;
            font-weight: 200;
          }
          @media @tablet {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 300;
          }
        }
      }
      .a_h3{
        &:hover{
          color: #f05623;
        }
      }
      & ul{
        width: 100%;
        margin-bottom: 15px;
        @media @mobile {
          display: none;
        }
        @media @tablet {
          display: block;
        }
        & li{
          & a{
            font-family: 'HelveticaNeueCyr';
            font-weight: 200;
            color: rgba(73, 73, 73, 1);
            @media @mobile {
              font-size: 14px;
            }
            @media @desktop-xl-1 {
              font-size: 16px;
            }
            @media @desktop-xl-2 {
              font-size: 20px;
            }
            &:hover{
              color: #f05623;
            }
          }
        }
      }
      .footer_cont_ic{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: 40px;
        @media @mobile {
          margin-bottom: 40px;
        }
        &.footer_cont_ic_1{
          width: 190px;
        }
        &.footer_cont_ic_2{
          width: 270px;
        }
      }
    }
  }
}

// /menu

input{
  width: 100%;
  height: 45px;
  background-color: transparent;
  border: 1px solid #768493;
  outline: none;
  text-align: left;
  font-size: 15px;
  color: rgba(132, 132, 132, 1);
  font-weight: 300;
  margin-bottom: 10px;
  padding: 0 10px;

  &::placeholder{
    text-align: left;
    font-size: 15px;
    color: rgba(132, 132, 132, 1);
    font-weight: 300;
  }

  &.invalid {
    border-color: #f00;
    border: 1px solid #f00;
    transition: .3s ease-out;
  }

  &.invalid::placeholder {
    color: #f00;
    transition: .3s ease-out;
  }
}

  
textarea{
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  background-color: transparent;
  border: 1px solid #A6A6A6;
  outline: none;
  text-align: left;
  font-size: 15px;
  color: rgba(49, 49, 49, 1);
  font-weight: 300;
  margin-bottom: 10px;
  padding: 20px;
  @media @mobile {
    max-height: 135px;
    min-height: 135px;
    height: 135px;
  }
  @media @tablet {
    max-height: 155px;
    min-height: 155px;
    height: 155px;
  }
  
  &::placeholder{
    font-size: 15px;
    font-weight: 200;
    color: rgba(181, 181, 181, 1);
  }

}

// pop_up >
  
.bg_pop_up {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2001;

  visibility: hidden;
  opacity: 0;
  transition: opacity .4s;


  .exit {
    position: absolute;
    cursor: pointer;
    z-index: 3;

    @media @mobile {
      width: 30px;
      height: 30px;
      top: 5px;
      right: 5px;
    }

    @media @desktop-xl {
      top: 50px;
      right: 50px;
    }

    .exit_1 {
      width: 20px;
      height: 2px;
      background: #ACACAC;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      z-index: 2;
    }

    .exit_2 {
      width: 20px;
      height: 2px;
      background: #ACACAC;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      z-index: 2;
    }
  }


  .popup {
    transition: all .4s ease;

    &.closed {
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, -100%);
    }
  }

  .pop-up {

    &.closed *,
    &.closed *::placeholder {
      transition: none !important;
    }
    
    // =========>

    &.thanks{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: linear-gradient(90deg, #111112 35%, #1b1b1b 75%);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media @mobile {
        width: 300px;
        height: 350px;
        padding: 40px;
      }
  
      @media @mobile-2-2 {
        width: 400px;
        height: 350px;
        padding: 60px;
      }
    }

    // =========>

    &.zaiavka{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      transition: .4s ease-out;

      @media @mobile {
        width: ~"calc(100vw - 30px)";
        height: ~"calc(100vh - 60px)";
        padding: 15px;
        right: -100%;
        padding: 30px;
        background: #F1F1F1;
      }
      @media @tablet {
        width: 420px;
        background: #ffffff;
      }
      @media @desktop-xl {
        width: 1100px;
        height: 100vh;
        padding: 45px 30px;
      }
  
      @media @mobile-2-2 {
      }
      .zaiavka_cont{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        & h2{
          text-align: left;
          @media @mobile {
            font-size: 18px;
            margin-bottom: 10px;
          }
          @media @desktop-xl {
            font-size: 38px;
            margin-bottom: 20px;
          }
        }
        .btn_orange{
          @media @mobile {
            width: 100%;
          }
          @media @tablet {
            width: 190px;
          }
        }
        & form{
          width: 400px;

          & p{
            color: rgba(137, 137, 137, 1);
            font-weight: 200;
            @media @mobile {
              font-size: 15px;
              margin-bottom: 10px;
            }
            @media @desktop-xl {
              font-size: 23px;
              margin-bottom: 20px;
            }
          }

          & input{
            width: 100%;
            height: 45px;
            border: 1px solid #A6A6A6;
            padding: 0 20px;
            border-radius: 0;
            color: rgba(49, 49, 49, 1);

            &::placeholder{
              font-size: 15px;
              font-weight: 200;
              color: rgba(181, 181, 181, 1);
            }
            &.invalid{
              border: 1px solid #f00;
              &::placeholder{
                color: #f00;
              }
            }
          }
          & textarea{
            max-width: 100%;
            min-width: 100%;
            width: 100%;
            &::placeholder{
              font-size: 15px;
              font-weight: 200;
              color: rgba(181, 181, 181, 1);
            }
          }
        }
        .zaiavka_cont_main{
          width: 300px;
          @media @mobile {
            display: none;
          }
          @media @desktop-xl {
            display: block;
          }

          .zaiavka_cont_main_block{
            width: 100%;
            margin-bottom: 50px;

            & h2{
              margin-bottom: 50px;
            }
            & h3{
              font-weight: 300;
              font-size: 20px;
              margin-bottom: 15px;
            }
            & p{
              font-weight: 200;
              font-size: 18px;
              color: rgba(137, 137, 137, 1);
              line-height: 1.6;
              & a{
                display: inline-block;
                font-weight: 200;
                font-size: 18px;
                color: rgba(137, 137, 137, 1);
                &:hover{
                  color: #f05623;
                }
              }
            }
          }
          .arr_all{
            &:hover{
              margin-left: -10px;
              padding-right: 50px;
            }
          }
        }
      }
    }

    // =========>

  }

  &.shown {
    visibility: visible;
    opacity: 1;
    
  .pop-up {
      &.zaiavka{
        right: 0;
      }
    }
  }
}




.gorizontal-slider-slides {
  position: relative;
  width: 100%;
}
.header_sl_min_img .gorizontal-slider-slides {
  width: 100% !important;
}
.gorizontal-slider-track:not(.slider_sl_track) {
  display: flex;
  position: relative;
  height: 100% !important;
  transition: left 0.4s;
}
.gorizontal-slider-track.slider_sl_track {
  display: flex;
  position: relative;
  height: auto;
  transition: left 0.4s, height .4s;
}
.header_sl_min_img .gorizontal-slider-item.movable {
  opacity: 0;
}


.gorizontal-slider-item.pseudo {
  opacity: 0;
  visibility: hidden;
}


.gorizontal-slider.fullmove .gorizontal-slider-dots li {
  transition: all .4s;
}
.gorizontal-slider.fullmove .gorizontal-slider-dots li:not(.active):not(.first):not(.last):not(.prev):not(.next):not(.tripledots) {
  width: 0;
  overflow: hidden;
}
.gorizontal-slider.fullmove .gorizontal-slider-dots li.tripledots {
  pointer-events: none;
}



/* Ihor: стилі хедера по скролу */
.top_menu {
  transition: top 1s;
}
.top_menu.scrolling {
  position: fixed;
  top: -120px;
}
.top_menu.scrolling.prescrolled {
  transition: top 1s;
  background-color: #373737;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  padding: 10px 0;
  .poisk {
    &:before{
      content: '';
      width: 200vw;
      height: 0;
      background-color: #373737;
      position: absolute;
      top: 35px;
      left: -100vw;
      transition: .3s ease-out;
      z-index: -1;
      @media @mobile {
        display: block;
      }
      @media @mobile-2-2 {
        display: none;
      }
    }
    &.active{
      &:before{
        height: 50px;
      }
    }
  }
  .block_v{
    &.active{
      &:after{
        height: 50px;
        cursor: auto;
      }
    }
  }
}
.top_menu.scrolling.scrolled {
  top: 0;
}
.body_content{
  @media @mobile {
    overflow: hidden;
  }
  @media @desktop-xl {
    overflow: visible;
  }
}
@media @desktop-xl {
  .overflow-visible {
    overflow: visible !important;
  }
  
}

.widther-slider {
  @media @desktop-xl {
    .gorizontal-slider-slides {
      width:150vw;
      margin-left: -25vw;

      .slider_sl_block_img {
        width: 50vw !important;
      }
    }
  }
}



.custom-slider-slides {
  position: relative;
  padding: 0;
  
  @media @desktop-xl {
    padding: 0 70px;
  }

  .custom-slider-track {
    position: relative;
    transition: left .4s;
  }
}