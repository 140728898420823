// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.silosy {
  background-color: #373737;
  position: relative;
  z-index: 2;
  @media @mobile {
    padding: 50px 0;
  }
  @media @desktop-xl {
    padding: 100px 0;
  }

  &__main{
    position: relative;

    &__item{
      position: relative;
      @media @mobile {
        width: 100%;
      }
      @media @desktop-xl {
        width: 50%;
        min-height: 400px;
      }
      @media @desktop-xl-2 {
        min-height: 600px;
      }

      & h2{
        color: #fff;
        @media @mobile {
          margin-bottom: 45px;
        }
        @media @desktop-xl {
          margin-bottom: 52px;
        }
      }
      .silosy_svg{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        @media @mobile {
          width: 100%;
          position: relative;
          justify-content: space-between;
        }
        @media @mobile-2 {
          justify-content: center;
        }
        @media @desktop-xl {
          position: absolute;
          top: 50px;
          right: -100%;
        }

        .silosy_svg_block{
          width: 100%;
          height: 100%;
          @media @mobile {
            width: 45%;
          }
          @media @desktop-xl {
            width: 50%;
          }
          & span{
            display: block;
            width: 100%;
            @media @mobile {
              height: 260px;
            }
            @media @desktop-xl {
              height: 100%;
            }
          }
        }
        .silosy_text{
          z-index: 1;
          @media @mobile {
            width: 40%;
            position: relative;
          }
          @media @mobile-2-2 {
            width: auto;
          }
          @media @desktop-xl {
            width: 300px;
            position: absolute;
            left: -35%;
            bottom: 50px;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
          }
          @media @desktop-xl-1 {
            width: 350px;
          }
          @media @desktop-xl-2 {
            width: 400px;
          }

          .silosy_p_1{
            font-family: 'oswald';
            font-weight: 300;
            text-align: right;
            text-transform: uppercase;
            color: rgba(90, 90, 90, 1);
            transition: 1s ease-out;
            @media @mobile {
              font-size: 18px;
              line-height: 1.8;
            }
            @media @desktop-xl {
              display: table;
              width: 300px;
              font-size: 20px;
              line-height: 1.2;
              margin-left: -100%;
            }
            @media @desktop-xl-1 {
              width: 350px;
              font-size: 24px;
            }
            @media @desktop-xl-2 {
              font-size: 30px;
              width: 400px;
            }
          } 
          .silosy_p_2{
            font-family: 'oswald';
            font-weight: 300;
            text-align: right;
            color: rgba(90, 90, 90, 1);
            transition: 1s ease-out;
            line-height: 1.1;
            @media @mobile {
              font-size: 18px;
              line-height: 1.8;
            }
            @media @desktop-xl {
              display: table;
              width: 300px;
              font-size: 20px;
              line-height: 1.2;
              margin-left: -100%;
            }
            @media @desktop-xl-1 {
              width: 350px;
              font-size: 54px;
            }
            @media @desktop-xl-2 {
              width: 400px;
            }
            & span{
              @media @mobile {
                font-size: 30px;
                line-height: 1.4;
              }
              @media @tablet {
                font-size: 35px;
              }
              @media @desktop-xl {
                font-size: 44px;
                line-height: 1.2;
              }
              @media @desktop-xl-1 {
                font-size: 54px;
              }
              @media @desktop-xl-2 {
                font-size: 64px;
              }
            }
          }
          &.anime{
            .silosy_p_1,
            .silosy_p_2{
              margin-left: 0;
            }
          }
        }
      }
      & ul{
        @media @mobile {
          width: fit-content;
          margin: 0 auto;
          margin-top: 45px;
        }
        @media @desktop-xl {
          margin-top: 0;
          width: auto;
        }
        & li{
          & a{
            color: #fff;
            font-family: 'HelveticaNeueCyr';
            font-weight: 200;
            transition: .3s ease-out;
            line-height: 1.8;
            @media @mobile {
              display: table;
              margin: 0 auto;
              font-size: 17px;
            }
            @media @desktop-xl {
              font-size: 18px;
              margin: 0;
            }
            @media @desktop-xl {
              font-size: 18px;
            }
            @media @desktop-xl-2 {
              font-size: 22px;
            }
            &:before{
              background: rgba(240, 86, 35, 1);;
            }
            &:hover{
              color: rgba(240, 86, 35, 1);
            }
          }
        }
      }
      .arrow_next_white{
        @media @mobile {
          margin: 0 auto;
          margin-top: 50px;
        }
        @media @desktop-xl {
          float: right;
          margin-top: 0;
        }
        @media @desktop-xl-2 {
          margin-top: 50px;
        }
      }
    }
  }
}