// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.objects {
  background-color: #ffffff;
  position: relative;
  @media @mobile {
    padding: 50px 0;
  }
  @media @desktop-xl {
    padding: 100px 0;
  }

  &__main{
    display: flex;
    @media @mobile {
      justify-content: center;
      flex-wrap: wrap;
      row-gap: 60px;
    }
    @media @desktop-xl {
      justify-content: space-between;
      row-gap: 150px;
    }

    .number_sec{
      top: 650px;

      &.number_sec_top_2{
        top: 750px;
      }
    }


    &__item{
      width: 100%;
      min-height: 345px;
      display: flex;
      position: relative;

      @media @mobile {
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 60px;
      }
      @media @desktop-xl {
        justify-content: flex-start;
        padding-top: 45px;
        padding-bottom: 0px;
      }

      .objects_text{
        position: relative;
        @media @mobile {
          padding-bottom: 50px;
        }
        @media @desktop-xl {
          width: 500px;
          padding-bottom: 60px;
        }
        @media @desktop-xl-1 {
          width: 575px;
        }
        @media @desktop-xl-2 {
          width: 635px;
        }

        & h3{
          display: inline-block;
          text-transform: uppercase;
          color: rgba(58, 58, 58, 1);
          font-family: 'HelveticaNeueCyr';
          font-weight: 300;
          margin-bottom: 15px;
          @media @mobile {
            font-size: 20px;
          }
          @media @desktop-xl {
            font-size: 25px;
          }
        }
        & p{
          color: rgba(58, 58, 58, 1);
          font-family: 'HelveticaNeueCyr';
          font-weight: 200;
          text-align: justify;
          line-height: 24.96px;
          @media @mobile {
            font-size: 16px;
            margin-bottom: 30px;
          }
          @media @desktop-xl {
            font-size: 18px;
          }
        }
        .arr_all{
          position: absolute;
          right: 0;
          bottom: 30px;
        }
      }
    }
    .arr_flex{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0 auto;
      padding: 0 30px;

      .al_arr{
        display: block;
        width: 9px;
        height: 16px;
        position: absolute;
        transition: .3s ease-out;
      }
      .al_arr_l{
        left: 0;
        top: 45%;
        transform: translateY(-50%) scale(1);
        background: url(../images/al_arr_l.svg) 50% 50% no-repeat;
        background-size: contain;
        &:hover{
          transform: translateY(-50%) scale(1.1);
        }
      }
      .al_arr_r{
        right: 0;
        top: 45%;
        transform: translateY(-50%) scale(1);
        background: url(../images/al_arr_r.svg) 50% 50% no-repeat;
        background-size: contain;
        &:hover{
          transform: translateY(-50%) scale(1.1);
        }
      }
      & ul{
        display: flex;
        justify-content: center;
        column-gap: 15px;
        & li{
          & a{
            font-size: 18px;
            color: rgba(146, 146, 146, 1);
            font-family: 'HelveticaNeueCyr';
            font-weight: 300;
            transition: .3s ease-out;
            &:hover{
              color: rgba(240, 86, 35, 1);
            }
            &.active{
              color: rgba(240, 86, 35, 1);
              font-weight: 400;
              text-decoration: revert;
            }
          }
        }
      }
    }
  }
}