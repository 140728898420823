// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.sirvis {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  background-color: #fff;
  position: relative;
  z-index: 3;
  @media @mobile {
    padding: 50px 0;
  }
  @media @desktop-xl {
    padding: 100px 0 100px 0;
  }

  &__main{

    .number_sec{
      top: 650px;
    }

    &__item{
      display: flex;
      flex-wrap: wrap;
      @media @mobile {
        row-gap: 100px;
        justify-content: space-between;
      }
      @media @desktop-xl {
        row-gap: 100px;
        column-gap: 125px;
        justify-content: flex-start;
      }
      @media @desktop-xl-1 {
        column-gap: 225px;
      }
      @media @desktop-xl-2 {
        column-gap: 325px;
      }

      .sirvis_block{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @media @mobile {
          width: 100%;
        }
        @media @tablet {
          width: 45%;
        }
        @media @desktop-xl {
          width: 38%;
        }

        .sirvis_icon{
          width: 200px;
          height: 170px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        & h3{
          width: 100%;
          text-align: center;
          display: inline-block;
          text-transform: uppercase;
          color: #3a3a3a;
          font-family: 'HelveticaNeueCyr';
          font-weight: 300;
          margin: 50px 0 10px 0;
          @media @mobile {
            font-size: 18px;
          }
          @media @desktop-xl {
            font-size: 30px;
          }
        }
        & p{
          color: #3a3a3a;
          font-family: 'HelveticaNeueCyr';
          font-weight: 200;
          text-align: justify;
          line-height: 24.96px;
          @media @mobile {
            font-size: 16px;
          }
          @media @desktop-xl {
            font-size: 18px;
          }
        }
        .only_arrow{
          display: inline-block;
          width: 41px;
          height: 19px;
          margin: 0 auto;
          overflow: hidden;
          position: relative;
          margin-top: 25px;
          .svg_orange{
            stroke: #f05623;
            transition: 0.4s ease-out;
            position: absolute;
            top: 0;
            left: -55px;
            transition: all.3s ease-out;
          }
          .svg_black{
            stroke: #000000;
            transition: 0.4s ease-out;
            position: absolute;
            top: 0;
            left: 0;
            transition: all.3s ease-out;
          }
          &:hover{
            .svg_orange{
              left: 0px;
            }
            .svg_black{
              left: 55px;
            }
          }
        }
        
      }
    }
  }
}