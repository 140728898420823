// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.separat {
  background-color: #fff;
  position: relative;
  z-index: 2;
  @media @mobile {
    padding: 50px 0;
  }
  @media @desktop-xl {
    padding: 100px 0;
  }

  &__main{
    width: 100%;
    position: relative;

    &__item{
      position: relative;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-content: center;
      @media @mobile {
        width: 100%;
      }
      @media @desktop-xl {
        width: 64%;
        height: 85vh;
      }

      & h2{
        width: 100%;
        @media @mobile {
          margin-bottom: 45px;
        }
        @media @desktop-xl {
          margin-bottom: 52px;
        }
      }
      .separat_svg{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media @mobile {
          width: 100%;
          padding: 40px 0;
          position: relative;
        }
        @media @desktop-xl {
          width: 115%;
          height: 100%;
          position: absolute;
          padding: 0;
          padding-left: 50px;
          top: 0;
          right: -85%;
        }
        &:before{
          @media @mobile {
            content: '';
            display: block;
            width: ~"calc(100% + 60px)";
            height: 100%;
            background:#DCDCDC;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
          }
          @media @desktop-xl {
            width: 100%;
            height: 100%;
          }
        }

        .separat_svg_block{
          position: relative;
          z-index: 3;
          @media @mobile {
            max-width: 500px;
            width: 100%;
            margin: 0 auto;
          }
          @media @desktop-xl {
            max-width: 70%;
            width: 100%;
            height: 100%;
            margin: 0;
          }
          & span{
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .separat_mask{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
          overflow: hidden;
        }
        .separat_text{
          position: absolute;
          z-index: 1;
          transition: 1s ease-out;
          @media @mobile {
            width: 100%;
            left: 50%;
            bottom: -74px;
            transform: translateX(-50%);
          }
          @media @tablet {
            bottom: -82px;
          }
          @media @desktop-xl {
            width: auto;
            left: -370px;
            bottom: 20px;
            transform: none;
          }
          @media @desktop-xl-1 {
            bottom: 20px;
          }
          @media @desktop-xl-2 {
            bottom: 50px;
          }
          .separat_p_1{
            font-family: 'oswald';
            font-weight: 300;
            text-transform: uppercase;
            color: #DCDCDC;
            @media @mobile {
              font-size: 18px;
              text-align: center;
              line-height: 1.8;
            }
            @media @desktop-xl {
              text-align: right;
              line-height: 1.2;
            }
            @media @desktop-xl-1 {
              font-size: 24px;
            }
            @media @desktop-xl-2 {
              font-size: 30px;
            }
          } 
          .separat_p_2{
            font-family: 'oswald';
            font-weight: 300;
            color: #DCDCDC;
            @media @mobile {
              font-size: 18px;
              text-align: center;
              line-height: 1.8;
            }
            @media @desktop-xl {
              text-align: right;
              line-height: 1.2;
            }
            @media @desktop-xl-1 {
              font-size: 54px;
            }
            & span{
              @media @mobile {
                font-size: 30px;
                text-align: left;
                line-height: 1.4;
              }
              @media @tablet {
                font-size: 35px;
              }
              @media @desktop-xl {
                font-size: 44px;
                text-align: right;
                line-height: 1.2;
              }
              @media @desktop-xl-1 {
                font-size: 54px;
              }
              @media @desktop-xl-2 {
                font-size: 64px;
              }
            }
          } 
          &.z_min{
            z-index: 3;
            .separat_p_1,
            .separat_p_2{
              color: #fff;
            }
          }
        }
      }
      .arrow_next{
        @media @mobile {
          margin: 0 auto;
          margin-top: 120px;
        }
        @media @desktop-xl {
          float: left;
          margin-top: 50px;
        }
      }
    }
  }
  &.anime{
    .separat_text{
      @media @desktop-xl {
        left: -160px;
      }
      @media @desktop-xl-1 {
        left: -190px;
      }
      @media @desktop-xl-2 {
        left: -110px;
      }
    }
  }
}