// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.catalog {
  background-color: #fff;
  position: relative;
  z-index: 2;
  overflow: hidden;
  &.catalog_dop{
    @media @desktop-xl {
      padding-top: 100px;
    }
  }

  .catalog_bg{
    width: 100%;
    height: 400px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #EAEAEA;
    clip-path: polygon(0 0, 100% 25%, 100% 100%, 0% 100%);
    @media @mobile {
      display: none;
    }
    @media @desktop-xl {
      display: block;
    }
  }

  &__main{
    width: 100%;
    position: relative;
    z-index: 2;

    & h2{
      @media @mobile {
        margin-bottom: 45px;
      }
      @media @desktop-xl {
        margin-bottom: 88px;
      }
    }

    .catalog_sl{
      width: 100%;
      position: relative;

      .catalog_sl_track{
        position: relative;
        display: flex;
        @media @mobile {
          width: 1000000%;
          justify-content: flex-start;
        }
        @media @desktop-xl {
          width: 100%;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        .catalog_cont{
          position: relative;
          z-index: 1;
          @media @mobile {
            max-width: 520px;
            width: ~"calc(100vw - 60px)";
            padding: 0 30px;
            margin: 0 auto;
          }
          @media @desktop-xl {
            max-width: none;
            width: 33.3333%;
            padding: 0;
            margin: 0;
          }

          &:before{
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(238, 238, 238, 0.75);
            z-index: 3;
            transition: .5s ease-out;
            @media @mobile {
              width: 0%;
            }
            @media @desktop-xl {
              width: 100%;
            }
          }
          .catalog_cont_img{
            width: 100%;
            @media @mobile {
              height: ~"calc(100% / 16 * 6)";
            }
            @media @mobile-2-2 {
              height: ~"calc(100% / 16 * 9)";
            }
            @media @desktop-xl {
              height: 100%;
            }
            
            & img{
              width: 100%;
              height: 100%;
              object-fit: cover;
              position: relative;
              z-index: 2;
            }
            & iframe{
              width: 100%;
              height: 100%;
              object-fit: cover;
              position: relative;
              z-index: 2;
            }
          }
          .catalog_cont_text{
            width: 100%;
            background-color: #fff;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            transition: .5s ease-out;
            @media @mobile {
              position: relative;
              padding-top: 20px;
            }
            @media @desktop-xl {
              height: 100%;
              position: absolute;
              top: 0;
              padding: 8% 8% 8% 7%;
              left: 0;
            }
            @media @desktop-xll {
              padding: 20% 20% 20% 19%;
            }

            .catalog_cont_text_all{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: wrap;
              transition: .5s ease-out;
              transition-delay: .5s;
              @media @mobile {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
              }
              @media @desktop-xl {
                clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
                padding-left: 15px;
              }

              .catalog_h3{
                position: relative;
                @media @mobile {
                  padding-bottom: 40px;
                }
                @media @desktop-xl {
                  padding-bottom: 70px;
                }

                & h3{
                  width: 100%;
                  font-family: 'HelveticaNeueCyr';
                  font-weight: 200;
                  color: rgba(0, 0, 0, 1);
                  @media @mobile {
                    font-size: 18px;
                  }
                  @media @desktop-xl {
                    font-size: 3vmin;
                  }
                  @media @desktop-xl-1 {
                    font-size: 2.5vmin;
                  }
                  @media @desktop-xl-2 {
                    font-size: 30px;
                  }
                  & br{
                    @media @mobile {
                      display: none;
                    }
                    @media @desktop-xl {
                      display: block;
                    }
                  }
                }

                .polo_block_rec{
                  width: 5px;
                  height: 5px;
                  background-color: #9E9E9E;
                  position: absolute;
                  left: -15px;
                  bottom: 93%;
                  opacity: 0;
                  transform: rotate(45deg);
                  @media @mobile {
                    left: 160px;
                    bottom: 19px;
                    filter: blur(0px);
                    opacity: 1;
                  }
                  @media @desktop-xl {
                    left: -15px;
                    bottom: 93%;
                    opacity: 0;
                    animation-delay: 1s;
                  }
                }

                .polo_block_polo{
                  height: 1px;
                  background-color: #9E9E9E;
                  position: absolute;
                  left: 0;
                  @media @mobile {
                    max-width: 165px;
                    width: 100%;
                    bottom: 21px;
                  }
                  @media @desktop-xl {
                    max-width: 0;
                    width: 100%;
                    bottom: 33px;
                  }
                }
                
              }

              .catalog_cont_text_p{
                & p{
                  font-family: 'HelveticaNeueCyr';
                  font-weight: 200;
                  color: rgba(116, 116, 116, 1);
                  line-height: 1.8;
                  @media @mobile {
                    font-size: 16px;
                  }
                  @media @desktop-xl {
                    font-size: 2.5vmin;
                  }
                  @media @desktop-xl-1 {
                    font-size: 2vmin;
                  }
                  @media @desktop-xl-2 {
                    font-size: 20px;
                  }
                }
              }
              .catalog_cont_text_p{
                width: 100%;
                font-family: 'HelveticaNeueCyr';
                font-weight: 200;
                color: rgba(116, 116, 116, 1);
                line-height: 1.8;
                padding-bottom: 40px;
                position: relative;
                @media @mobile {
                  font-size: 16px;
                }
                @media @desktop-xl {
                  font-size: 2.5vmin;
                }
                @media @desktop-xl-1 {
                  font-size: 2vmin;
                }
                @media @desktop-xl-2 {
                  font-size: 20px;
                }
                .arr_all{
                  position: absolute;
                  bottom: 0;
                  @media @mobile {
                    left: 0;
                  }
                  @media @desktop-xl {
                    right: 10px;
                  }
                }
              }
            }
          }
          &.active{
            z-index: 2;
          }
          &:hover{
            @media @desktop-xl {
              &:before{
                width: 0;
              }
              .catalog_cont_text{
                left: 100%;
  
                .catalog_cont_text_all{
                  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  
                  .polo_block_rec{
                    animation: polo_block_rec 1s ease-in-out;
                    animation-fill-mode: forwards;
                    animation-delay: 1s;
                  }
  
                  .polo_block_polo{
                    animation: polo_block_polo 1s ease-in-out;
                    animation-fill-mode: forwards;
                    animation-delay: 1s;
                  }
                  @keyframes polo_block_polo {
                    0% {
                      max-width: 0;
                    }
                    50% {
                      max-width: 0;
                    }
                    100% {
                      max-width: 165px;
                    }
                  }
                }
              }
            }
          }
          &.right_block{
            .catalog_cont_text{
              @media @mobile {
                padding-top: 20px;
                text-align: left;
              }
              @media @desktop-xl {
                padding: 8% 8% 8% 7%;
                text-align: right;
              }
              @media @desktop-xll {
                padding: 20% 20% 20% 19%;
              }

              .catalog_cont_text_all{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                transition: .5s ease-out;
                transition-delay: .5s;
                @media @mobile {
                  justify-content: flex-start;
                  clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
                }
                @media @desktop-xl {
                  justify-content: flex-end;
                  clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
                  padding-right: 15px;
                }

                .catalog_h3{    
                  @media @desktop-xl {
                    .polo_block_rec{
                      left: auto;
                      right: -15px;
                    }
                    .polo_block_polo{
                      left: auto;
                      right: 0;
                    }
                  }

                }
                .arr_all{
                  left: 0;
                  @media @desktop-xl {
                    right: 0;
                    left: auto;
                  }
                }
              }
            }
            &:hover{
              @media @desktop-xl {
                &:before{
                  width: 0;
                  left: 100%;
                }
                .catalog_cont_text{
                  left: -100%;
  
                  .catalog_cont_text_all{
                    animation: none;
                    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
  
                    .polo_block_rec{
                      animation: polo_block_rec_r 1s ease-in-out;
                      animation-fill-mode: forwards;
                      animation-delay: 1s;
                    }
        
                    .polo_block_polo{
                      animation: polo_block_polo_r 1s ease-in-out;
                      animation-fill-mode: forwards;
                      animation-delay: 1s;
                    }
                  }
                }
              }
            }
            &.double{
              width: 66.666%;
              height: ~"calc(width / 16 * 6)";
              .catalog_cont_img{
                @media @mobile {
                  height: ~"calc(100vh / 16 * 6)";
                }
                @media @desktop-xl {
                  height: 100%;
                }
              }
      
              .catalog_cont_text{
                @media @mobile {
                  width: 100%;
                  padding-top: 20px;
                }
                @media @desktop-xl {
                  width: 50%;
                  padding: 8% 7% 8% 8%;
                }
              }
              &:hover{
                @media @desktop-xl {
                  .catalog_cont_text{
                    left: -50%;
                  }
                }
              }
            }
            @keyframes polo_block_rec {
              0% {
                opacity: 0;
                left: -15px;
                bottom: 93%;
                filter: blur(0px);
              }
              0.1% {
                opacity: 1;
              }
              25% {
                filter: blur(2px);
              }
              50% {
                left: -15px;
                bottom: 31px;
                filter: blur(0px);
              }
              75% {
                filter: blur(2px);
              }
              100% {
                left: 160px;
                bottom: 31px;
                filter: blur(0px);
                opacity: 1;
              }
            }
            @keyframes polo_block_rec_r {
              0% {
                opacity: 0;
                right: -15px;
                bottom: 93%;
                filter: blur(0px);
              }
              0.1% {
                opacity: 1;
              }
              25% {
                filter: blur(2px);
              }
              50% {
                right: -15px;
                bottom: 31px;
                filter: blur(0px);
              }
              75% {
                filter: blur(2px);
              }
              100% {
                right: 160px;
                bottom: 31px;
                filter: blur(0px);
                opacity: 1;
              }
            }
            @keyframes polo_block_polo {
              0% {
                max-width: 0;
              }
              50% {
                max-width: 0;
              }
              100% {
                max-width: 165px;
              }
            }
            @keyframes polo_block_polo_r {
              0% {
                max-width: 0;
              }
              50% {
                max-width: 0;
              }
              100% {
                max-width: 165px;
              }
            }
          }
        }
      }
      .arr_l{
        position: absolute;
        left: ~"calc(100vw - 100px)";
        bottom: 70px;
        z-index: 3;
        @media @mobile {
          display: block;
        }
        @media @desktop-xl {
          display: none;
        }
      }
      .arr_r{
        position: absolute;
        left: ~"calc(100vw - 45px)";
        bottom: 70px;
        z-index: 3;
        @media @mobile {
          display: block;
        }
        @media @desktop-xl {
          display: none;
        }
      }
    }
  }
}