// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.conducting {
  background-color: #fff;
  position: relative;
  z-index: 1;
  &:before{
    content: '';
    display: block;
    width: 100%;
    height: 120vh;
    background-color: #fff;
    position: absolute;
    bottom: -120vh;
    left: 0;
    z-index: -2;
  }
  .conducting_bg{
    background-color: #EAEAEA;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
    position: relative;
    z-index: 2;
    @media @mobile {
      padding: 50px 0;
    }
    @media @desktop-xl {
      padding: 150px 0;
    }
  }

  &__main{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .conducting_block{
      display: flex;
      align-items: center;
      @media @mobile {
        justify-content: flex-end;
        flex-wrap: wrap;
      }
      @media @tablet {
        justify-content: flex-start;
        flex-wrap: nowrap;
      }
      & h2{
        text-align: right;
        font-weight: 300;
        @media @mobile {
          width: 100%;
          font-size: 20px;
        }
        @media @tablet {
          width: auto;
          font-size: 24px;
        }
        @media @desktop-xl {
          font-size: 28px;
        }
        @media @desktop-xl-1 {
          font-size: 32px;
        }
        @media @desktop-xl-2 {
          font-size: 40px;
        }
        & br{
          @media @mobile {
            display: none;
          }
          @media @mobile-2-1 {
            display: block;
          }
        }
      }
      .arrow_next{
        @media @mobile {
          margin-top: 30px;
          flex: 0 0 60px;
        }
        @media @tablet {
          margin-top: 0;
          margin-left: 50px;
        }
        @media @desktop-xl-2 {
          flex: 0 0 80px;
        }
      }
    }
  }
}