// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.product {
  background-color: #ffffff;
  position: relative;
  &.new{
    @media @mobile {
      padding-top: 50px;
    }
    @media @desktop-xl {
      padding-top: 100px;
    }

    .date_news{
      color: rgba(90, 90, 90, 1);
      font-family: 'HelveticaNeueCyr';
      font-weight: 200;
      @media @mobile {
        font-size: 15px;
        margin-bottom: 45px;
      }
      @media @desktop-xl {
        font-size: 17px;
        margin-bottom: 15px;
      }
    }
  }
  &.pad_b{
    padding-bottom: 50px;
  }
  &.pad_b_m{
    @media @mobile {
      padding-bottom: 100px;
    }
    @media @desktop-xl {
      padding-bottom: 50px;
    }
  }
.product_box{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  @media @mobile {
    display: none;
  }
  @media @desktop-xl {
    display: block;
  }
  .block_shadow_3_1_1{
    left: -760px;
    bottom: 20%;
    transform: none;
  }
  .block_shadow_3_1_2{
    top: 20%;
    right: -760px;
    left: auto;
    transform: none;
  }
}

  &__main{
    display: flex;
    position: relative;
    z-index: 2;
    @media @mobile {
      justify-content: center;
      flex-wrap: wrap;
    }
    @media @desktop-xl {
      justify-content: space-between;
    }

    .number_sec{
      top: 650px;
      &.number_sec_top_2{
        top: 750px;
      }
    }

    .product_svg_cont{
      position: relative;
      @media @mobile {
        width: 100%;
        margin-bottom: 45px;
      }
      @media @desktop-xl {
        width: ~'calc(100% - 720px)';
        margin-bottom: 0;
      }
      @media @desktop-xl-1 {
        width: ~'calc(100% - 920px)';
      }
      @media @desktop-xl-2 {
        width: ~'calc(100% - 1020px)';
      }
      &.pad_bot{
        @media @desktop-xl {
          padding-bottom: 198px;
        }
        @media @desktop-xl-2 {
          padding-bottom: 173px;
        }
      }
      &.pad_b_45{
        @media @desktop-xl {
          padding-bottom: 45px;
        }
      }

      .product_svg{
        position: sticky;
        top: 80px;
        left: 0;
        @media @mobile {
          max-width: 300px;
          width: 100%;
          height: auto;
          margin: 0 auto;
        }
        @media @desktop-xl {
          max-width: 80%;
          height: 360px;
          margin: 0 auto;
          top: 250px;
          margin-bottom: 0;
        }
        &.ic_svg_1{
          height: 200px;
        }
        &.ic_svg_2{
          height: 160px;
        }
        & svg{
          display: block;
          margin: 0 auto;
          height: 100%;
        }
        &.product_svg_img{
          height: auto;
          & img{
            display: block;
            width: 100%;
            object-fit: contain;
            margin: 0 auto; 
          }
        }
        &.team{
          width: 350px;
          & img{
            width: 100%;
            height: 410px;
            object-fit: cover;
          }
        }
        & h3{
          display: table;
          text-align: center;
          margin: 0 auto;
          color: #212121;
          font-size: 20px;
          font-family: 'HelveticaNeueCyr';
          font-weight: 300;
          text-transform: uppercase;
          transition: all 0.3s ease-out;
          @media @mobile {
            margin-top: 20px;
            opacity: 1;
          }
          @media @desktop-xl {
            width: 80%;
            margin-top: 45px;
            opacity: 0;
          }
          @media @desktop-xl-2 {
            width: 100%;
          }
        }
        &.active{
          & h3{
            opacity: 1;
          }
        }
        .product_svg_title{
          margin-top: 30px;
          text-align: center;
          color: rgba(58, 58, 58, 1);
          & h4{
            text-transform: uppercase;
            font-weight: 300;
            @media @mobile {
              font-size: 18px;
            }
            @media @desktop-xl {
              font-size: 20px;
            }
          }
          .polo{
            width: 100%;
            height: 1px;
            background-color: #E4E4E4;
            margin: 10px 0;
          }
          & p{
            font-weight: 200;
            @media @mobile {
              font-size: 16px;
            }
            @media @desktop-xl {
              font-size: 20px;
            }
          }
        }
      }
    }

    .product_top{
      width: 100%;
      @media @mobile {
        margin-bottom: 45px;
      }
      @media @desktop-xl {
        margin-bottom: 70px;
        max-height: 56px;
        height: 100%;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      }
      .product_top_all{
        @media @mobile {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          row-gap: 30px;
          column-gap: 60px;
          transition: .5s ease-out;
        }
        @media @tablet {
          flex-wrap: nowrap;
          row-gap: 0px;
        }
        @media @desktop-xl {
          width: 720px;
          float: right;
          margin-top: 60px;
        }
        @media @desktop-xl-1 {
          width: 970px;
        }

        .product_top_cont{
          display: flex;
          justify-content: center;
          position: relative;
          align-items: center;
          &:nth-child(1){
            &:before{
              content: '';
              width: 1px;
              height: 120%;
              position: absolute;
              top: 50%;
              right: -30px;
              transform: translateY(-50%);
              background-color: #AEAEAE;
              @media @mobile {
                display: none;
              }
              @media @tablet {
                display: block;
              }
            }
          }
          .p_cont_1,
          .p_cont_3{
            text-align: right;
            text-transform: uppercase;
            font-family: 'HelveticaNeueCyr';
            font-weight: 300;
            color: rgba(120, 120, 120, 1);
            @media @mobile {
              font-size: 12px;
            }
            @media @mobile-2-2 {
              font-size: 14px;
            }
            @media @desktop-xl-1 {
              font-size: 14px;
            }
          }
          .p_cont_3{
            display: flex;
            height: 100%;
            align-items: flex-end;
            padding-bottom: 5px;
          }
          .p_cont_2{
            text-transform: uppercase;
            font-family: 'oswald';
            font-weight: 400;
            color: rgba(58, 58, 58, 1);
            @media @mobile {
              font-size: 20px;
              margin: 0 10px;
            }
            @media @mobile-2-2 {
              font-size: 24px;
            }
            @media @tablet {
              margin: 0 25px;
            }
            @media @desktop-xl {
              font-size: 28px;
            }
            @media @desktop-xl-1 {
              font-size: 45px;
            }
          }
        }
      }
      &.anime{
        .product_top_all{
          margin-top: 0;
        }
      }
    }

    &__item{
      @media @mobile {
        width: 100%;
      }
      @media @desktop-xl {
        width: 720px;
      }
      @media @desktop-xl-1 {
        width: 920px;
      }
      @media @desktop-xl-2 {
        width: 1020px;
      }
      .product_sec{
        width: 100%;
        margin-bottom: 40px;
            
        .block_v{
          max-width: 395px;
          width: 100%;
          box-shadow: inset 0px 0px 0px 1px #CAD3DC;
          position: relative;
          &:nth-child(1){
            z-index: 5;
          }
          &:nth-child(2){
            z-index: 4;
          }
          &:nth-child(3){
            z-index: 3;
          }
          &:nth-child(4){
            z-index: 2;
          }
          &:nth-child(5){
            z-index: 1;
          }
          .block_arrow{
            width: 28px;
            height: 35px;
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
            border-radius: 5px;
            background-color: #C7CFD7;
            &:before{
              content: '';
              display: block;
              width: 5px;
              width: 10px;
              height: 5px;
              background: url(../images/arr_vipod.svg) 50% 50% no-repeat;
              background-size: contain;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(0);
              transition: .3s ease-out;
            }
          }
          .block_v_cont{
            width: 100%;
            height: 45px;
            display: flex;
            align-items: center;
            padding: 0 25px;
            position: relative;
            font-size: 16px;
            line-height: 19.01px;
            cursor: pointer;
            & p{
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 0;
            }
            &:before{
              content: '';
              display: block;
              width: 20px;
              height: 20px;
              position: absolute;
              top: 50%;
              right: 25px;
              transform: translateY(-50%) rotate(0);
              background: url(../images/vipod.svg) 50% 50% no-repeat;
              background-size: contain;
              transition: 0.3s ease-out;
            }
          }
          .block_v_s{
            width: 100%;
            height: 0px;
            min-height: 0px;
            position: absolute;
            top: 44px;
            left: 0;
            transition: .3s ease-out;
            overflow: hidden;
            box-shadow: inset 0px 0px 0px 1px #CAD3DC;
            background-color: #fff;
            .block_v_s_text{
              display: flex;
              align-items: center;
              min-height: 30px;
              padding: 0 25px;
              position: relative;
              font-size: 14px;
              font-weight: 300;
              margin-bottom: 0;
              line-height: 19.01px;
              cursor: pointer;
              a{
                width: 100%;
                cursor: pointer;
                color: #3a3a3a;
                &:before,
                &:after {
                  background: #898989;
                }
              }
              &:hover{
                background-color: #cad3dc;
              }
            }
          }
          &.opened {
            .block_arrow{
              &:before{
                transform: translate(-50%, -50%) rotate(180deg);
              }
            }

            .block_v_cont{
              &:before{
                transform: translateY(-50%) rotate(180deg);
              }
            }
            .block_v_s{
              height: 100%;
              min-height: 120px;
            }
            &.active{
              .block_v_s{
                overflow: auto;
              }
            }
          }
        }
        
        .product_sec_form{
          width: 100%;
          background-color: #EFF1F3;
          @media @mobile {
            margin-top: 15px;
            padding: 30px 10px;
          }
          @media @tablet {
            padding: 30px;
          }
          @media @desktop-xl {
            padding: 60px 90px;
            margin-top: 50px;
          }

          & form{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            row-gap: 25px;

            .btn_orange{
              margin: 0 auto;
              @media @mobile {
                font-size: 13px;
              }
              @media @mobile-2 {
                font-size: 16px;
              }
              @media @desktop-xl {
                margin-top: 15px;
              }
            }

            .form_cont{
              width: 100%;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              row-gap: 25px;
              &:nth-child(1){
                z-index: 5;
              }
              &:nth-child(2){
                z-index: 4;
              }
              &:nth-child(3){
                z-index: 3;
              }
              &:nth-child(4){
                z-index: 2;
              }
              &:nth-child(5){
                z-index: 1;
              }

              .form_block{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                row-gap: 25px;
                &:nth-child(1){
                  z-index: 5;
                }
                &:nth-child(2){
                  z-index: 4;
                }
                &:nth-child(3){
                  z-index: 3;
                }
                &:nth-child(4){
                  z-index: 2;
                }
                &:nth-child(5){
                  z-index: 1;
                }

                @media @mobile {
                  width: 100%;
                }
                @media @tablet {
                  width: ~'calc(50% - 20px)';
                }

                .form_block_inp{
                  width: 100%;

                  & span{
                    display: table;
                    font-size: 14px;
                    font-family: 'HelveticaNeueCyr';
                    font-weight: 300;
                    color: rgba(58, 58, 58, 1);
                    margin-bottom: 5px;
                  }
                  & input{
                    width: 100%;
                    padding: 0 10px;
                    background-color: #fff;
                    border: 1px solid #CAD3DC;
                    margin: 0;
                  }
                }
                .form_block_v{
                  width: 100%;
                  position: relative;
                  &:nth-child(1){z-index: 10;}
                  &:nth-child(2){z-index: 9;}
                  &:nth-child(3){z-index: 8;}
                  &:nth-child(4){z-index: 7;}
                  &:nth-child(5){z-index: 6;}
                  &:nth-child(6){z-index: 5;}
                  &:nth-child(7){z-index: 4;}
                  &:nth-child(8){z-index: 3;}
                  &:nth-child(9){z-index: 2;}
                  &:nth-child(10){z-index: 1;}

                  &.min{
                    @media @mobile {
                      width: 100%;
                    }
                    @media @tablet {
                      width: ~'calc(50% - 20px)';
                    }
                  }
                }
              }
            }
            .block_v{
              max-width: 100%;
              width: 100%;
              background-color: #fff;
              .block_v_cont {
                & p{
                  font-size: 14px;
                  font-weight: 200;
                  color: rgba(132, 132, 132, 1);
                }
              }
              .block_v_s{
                & a{
                  font-size: 14px;
                  font-weight: 200;
                  color: rgba(132, 132, 132, 1);
                }
              }
            }
            .form_block_v{
              &.min_3{
                position: relative;
                &:nth-child(1){z-index: 10;}
                &:nth-child(2){z-index: 9;}
                &:nth-child(3){z-index: 8;}
                &:nth-child(4){z-index: 7;}
                &:nth-child(5){z-index: 6;}
                &:nth-child(6){z-index: 5;}
                &:nth-child(7){z-index: 4;}
                &:nth-child(8){z-index: 3;}
                &:nth-child(9){z-index: 2;}
                &:nth-child(10){z-index: 1;}
                @media @mobile {
                  width: 100%;
                }
                @media @tablet {
                  width: ~'calc(33.3% - 20px)';
                }
              }

              & span{
                display: table;
                font-size: 14px;
                font-family: 'HelveticaNeueCyr';
                font-weight: 300;
                color: rgba(58, 58, 58, 1);
                margin-bottom: 5px;
              }
            }
          }

        }

        .contacts_sec{
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          @media @mobile {
            row-gap: 50px;
          }
          @media @desktop-xl {
            row-gap: 100px;
          }

          .contacts_sec_block{
            width: 100%;
            position: relative;

            .contacts_sec_block_text{
              @media @mobile {
                width: 100%;
              }
              @media @desktop-xl {
                width: 330px;

                min-height: 335px;
              }
              @media @desktop-xl-1 {
                width: 400px;
              }

              & p{
                margin-bottom: 15px;
                &.br_mob{
                  & br{
                    @media @mobile {
                      display: none;
                    }
                    @media @desktop-xl-1 {
                      display: block;
                    }
                  }
                }
              }
            }
            .contacts_map{
              border: 1px solid #BABABA;
              @media @mobile {
                width: 100%;
                margin-top: 30px;
                height: 235px;
              }
              @media @tablet {
                height: 335px;
              }
              @media @desktop-xl {
                width: ~'calc(100vw - 110%)';
                left: 350px;
                position: absolute;
                top: 0;
                margin-top: 0;
              }
              @media @desktop-xl-1 {
                left: 400px;
              }
              @media @desktop-xl-2 {
                left: 450px;
              }
            }
          }
        }

        .product_svg{
          max-width: 720px;
          margin: 0 auto;
          & img{
            width: 100%;
            object-fit: cover;
          }
        }
        & h3{
          display: inline-block;
          text-transform: uppercase;
          color: rgba(58, 58, 58, 1);
          font-family: 'HelveticaNeueCyr';
          font-weight: 300;
          margin-bottom: 15px;
          @media @mobile {
            font-size: 20px;
          }
          @media @desktop-xl {
            font-size: 30px;
          }
        }
        & h4{
          display: inline-block;
          text-transform: uppercase;
          color: rgba(58, 58, 58, 1);
          font-family: 'HelveticaNeueCyr';
          font-weight: 200;
          margin-bottom: 15px;
          @media @mobile {
            font-size: 18px;
          }
          @media @desktop-xl {
            font-size: 25px;
          }
        }
        & p,
        & ul li{
          color: rgba(58, 58, 58, 1);
          font-family: 'HelveticaNeueCyr';
          font-weight: 200;
          text-align: justify;
          line-height: 24.96px;
          @media @mobile {
            font-size: 16px;
          }
          @media @desktop-xl {
            font-size: 18px;
          }
          & a{
            color: rgba(58, 58, 58, 1);
            font-family: 'HelveticaNeueCyr';
            font-weight: 200;
            line-height: 24.96px;
            transition: .3s ease-out;
            @media @mobile {
              font-size: 16px;
            }
            @media @desktop-xl {
              font-size: 18px;
            }
            &:hover{
              color: #f05623;
            }
          }
        }
        .p_italic_b{
          font-style: italic;
          font-weight: 400;
        }
        .p_italic{
          font-style: italic;
          position: relative;
          @media @desktop-xl {
            width: 100%;
            padding-top: 150px;
            padding-left: 100px;
            line-height: 1.7;
          }
          &.kov{
            &:before{
              content: '“';
              font-family: 'HelveticaNeueCyr';
              font-size: 500px;
              font-weight: 300;
              line-height: 1;
              color: rgba(242, 244, 246, 1);
              position: absolute;
              left: -150px;
              z-index: -1;
              @media @mobile {
                top: -100px;
              }
              @media @tablet {
                top: 0px;
              }
            }
          }
        }
        & p,
        & ul{
          margin-bottom: 25px;
        }
        & ul{
          padding-left: 10px;
        }
        & ul li{
          padding-left: 20px;
          position: relative;
          &:before{
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: rgba(58, 58, 58, 1);
            position: absolute;
            top: 10px;
            left: 0;
          }
        }
        .mt_30{
          margin-top: 30px;
        }
        .mt_100{
          margin-top: 100px;
        }
        .financial{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
          row-gap: 130px;
          padding-top: 60px;

          .financial_block{
            width: 100%;
            display: flex;
            align-items: flex-start;
            @media @mobile {
              justify-content: center;
              flex-wrap: wrap;
            }
            @media @tablet {
              justify-content: space-between;
              flex-wrap: nowrap;
            }

            .financial_img{
              height: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-weight: 400;
              font-size: 30px;
              text-align: center;
              @media @mobile {
                width: 100%;
              }
              @media @mobile-2-1 {
                width: 385px;
                flex: 0 0 385px;
              }
              @media @mobile {
                margin-bottom: 30px;
              }
              @media @tablet {
                margin-bottom: 0;
              }
              & img{
                max-width: 100%;
                width: auto;
              }
            }
            .financial_img_text{
              position: relative;
              @media @mobile {
                width: 100%;
              }
              @media @tablet {
                width: auto;
                padding-left: 30px;
              }
              @media @desktop-xl-2 {
                padding-left: 65px;
              }
              & h4{
                @media @mobile {
                  text-align: center;
                }
                @media @tablet {
                  text-align: left;
                }
              }
              & p,
              & ul,
              & ol{
                margin: 0;
                margin-bottom: 15px;
              }
              .arr_all{
                position: absolute;
                bottom: -45px;
                right: 0;
              }
              & form{
                margin-top: 30px;

                .financial_block{
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  @media @mobile {
                    row-gap: 15px;
                    flex-wrap: wrap;
                  }
                  @media @mobile-2-2 {
                    column-gap: 15px;
                    flex-wrap: nowrap;
                  }
                  & input{
                    width: 100%;
                  }
                  .btn_orange{
                    width: 165px;
                    flex: 0 0 165px;
                  }
                }
              }
            }
          }
        }
        .catalog{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
          row-gap: 130px;
          padding-top: 60px;

          .catalog_block{
            width: 100%;
            min-height: 340px;

            .catalog_img_text{
              position: relative;
              @media @mobile {
                width: 100%;
              }
              @media @tablet {
                width: auto;
                padding-left: 285px;
              }
              @media @desktop-xl-2 {
                padding-left: 320px;
              }
              & h4{
                @media @mobile {
                  text-align: center;
                }
                @media @tablet {
                  text-align: left;
                }
              }
              & p,
              & ul,
              & ol{
                margin: 0;
                margin-bottom: 15px;
              }

              .catalog_img{
                width: 255px;
                flex: 0 0 255px;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-weight: 400;
                font-size: 30px;
                text-align: center;
                @media @mobile {
                  margin: 0 auto;
                }
                @media @tablet {
                  margin-top: 0;
                  margin-bottom: 0;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
              .catalog_ic{
                width: fit-content;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                column-gap: 15px;
                @media @mobile {
                  position: relative;
                  margin: 0 auto;
                }
                @media @tablet {
                  margin-top: 0;
                  position: absolute;
                  bottom: -65px;
                  left: 285px;
                }
                @media @desktop-xl-2 {
                  left: 320px;
                }
              }
            }
          }
        }
        .product_sec_bot{
          display: flex;
          align-items: center;
          @media @mobile {
            justify-content: center;
            flex-wrap: wrap;
            row-gap: 30px;
          }
          @media @tablet {
            justify-content: space-between;
            row-gap: 30px;
            column-gap: 30px;
          }
          .arr_all_l{
            padding-right: 40px;
            &:hover{
              padding-right: 30px;
            }
          }
        }
        .new_img_all_block{
          width: 100%;
          margin: 25px 0;
        }
        .new_img_desc{
          display: inline-block;
          @media @mobile {
            width: 100%;
            height: ~'calc(100vw / 16 * 9)';
            margin: 0 0 25px 0;
            object-fit: cover;
          }
          @media @desktop-xl {
            width: auto;
            height: auto;
            float: right;
            margin: 0 0 45px 45px;
          }
        }
        .zaglushka{
          position: relative;
          @media @mobile {
            width: 100%;
            margin: 0 0 25px 0;
            height: ~'calc(100vw  / 16  * 9)';
          }
          @media @tablet {
            height: 490px;
          }
          @media @desktop-xl {
            width: 250px;
            margin: 0 0 45px 45px;
            height: 490px;
            float: right;
          }
          &:after{
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../images/zaglushka.jpg) 50% 50% no-repeat;
            background-size: cover;
            z-index: 1;
            transition: .3s ease-out;
            @media only screen and (min-width: 320px) {
              width: 100%;
            }
            @media only screen and (min-width: 1200px) {
              width: ~'calc(100vw - 880px)';
            }
            @media only screen and (min-width: 1366px) {
              width: ~'calc(100vw - 1000px)';
            }
            @media only screen and (min-width: 1600px) {
              width: ~'calc(100vw - 1260px)';
            }
            @media only screen and (min-width: 1768px) {
              width: ~'calc(100vw - 1390px)';
            }
          }
        }
        .product_sec_block{
          background-color: #403F47;
          position: relative;
          @media @mobile {
            width: 100%;
            margin: 0 0 45px 0;
            padding: 100px 0 70px 20px;
          }
          @media @desktop-xl {
            width: 320px;
            float: right;
            margin: 0 0 45px 45px;
            padding: 100px 25px 100px 65px;
          }
          &:before{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            border: 20px solid transparent;
            border-left: 32px solid #fff;
            border-top: 12px solid #fff;
            z-index: 2;
          }
          &:after{
            content: '';
            display: block;
            display: block;
            width: 100vw;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #403F47;
            z-index: 1;
          }
          .product_sec_block_cont{
            position: relative;
            z-index: 2;
            @media @mobile {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-end;
              column-gap: 15px;
              row-gap: 20px;
            }
            @media @tablet {
              justify-content: space-around;
            }
            @media @desktop-xl {
              justify-content: flex-end;
            }

            .help{
              width: 50px;
              height: 50px;
              background: url(../images/help.svg) 50% 50% no-repeat;
              background-size: contain;
              margin-bottom: 20px;
              position: absolute;
              top: -60px;
              @media @mobile {
                left: 10px;
              }
              @media @desktop-xl {
                left: -45px;
              }
            }
            & a{
              display: table;
              font-size: 16px;
              color: #fff;
              text-transform: uppercase;
              font-family: 'HelveticaNeueCyr';
              font-weight: 200;
              position: relative;
              text-align: right;
              @media @mobile {
                padding-right: 50px;
                margin-right: 15px;
              }
              @media @desktop-xl {
                padding-right: 60px;
                float: right;
                margin-right: 0;
              }

              & svg{
                width: 39px;
                height: 17px;
                stroke: #EC8F34;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                transition: .3s ease-out;
              }
              &:hover{
                & svg{
                  stroke: #f05623;
                  @media @mobile {
                    right: -5px;
                  }
                  @media @desktop-xl {
                    right: -15px;
                  }
                }
              }
            }
            & p{
              color: #fff;
              font-size: 21px;
              font-family: 'oswald';
              font-weight: 100;
              text-align: justify;
              position: relative;
              margin: 0;
              margin-top: 25px;
              @media @desktop-xl {
                float: right;
              }
              &:before{
                content: '“';
                display: block;
                position: absolute;
                top: -50px;
                font-size: 280px;
                color: rgba(255, 255, 255, 0.21);
                font-family: 'oswald';
                font-weight: 300;
                line-height: 1;
                @media @mobile {
                  left: 0px;
                }
                @media @desktop-xl {
                  left: -60px;
                }
              }
            }
          }
        }
        .product_sec_icons{
          margin-top: 60px;
          @media @mobile {
            margin-bottom: 60px;
          }
          @media @desktop-xl {
            margin-bottom: 100px;
          }
          & h3  {
            @media @mobile {
              margin-bottom: 20px;
            }
            @media @desktop-xl {
              margin-bottom: 60px;
            }
          }
          .product_sec_cont{
            width: 100%;
            display: flex;
            @media @mobile {
              justify-content: center;
              row-gap: 45px;
              flex-wrap: wrap;
            }
            @media @tablet {
              justify-content: space-between;
            }
            @media @desktop {
              row-gap: 60px;
            }

            .industries_block{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              align-items: center;
              @media @mobile {
                width: 100%;
              }
              @media @tablet {
                width: 360px;
              }
              @media @desktop-xl-1 {
                width: 430px;
              }

              .industries_block_icon{
                @media @mobile {
                  width: 60px;
                  height: 40px;
                  flex: 0 0 60px;
                  margin-right: 20px;
                }
                @media @desktop {
                  width: 80px;
                  height: 60px;
                  flex: 0 0 80px;
                  margin-right: 30px;
                }
                &.icon_1{
                  background: url(../images/icon_1.svg) 50% 50% no-repeat;
                  background-size: contain;
                }
                &.icon_2{
                  background: url(../images/icon_2.svg) 50% 50% no-repeat;
                  background-size: contain;
                }
                &.icon_3{
                  background: url(../images/icon_3.svg) 50% 50% no-repeat;
                  background-size: contain;
                }
                &.icon_4{
                  background: url(../images/icon_4.svg) 50% 50% no-repeat;
                  background-size: contain;
                }
                &.icon_5{
                  background: url(../images/icon_5.svg) 50% 50% no-repeat;
                  background-size: contain;
                }
                &.icon_6{
                  background: url(../images/icon_6.svg) 50% 50% no-repeat;
                  background-size: contain;
                }
                &.icon_7{
                  background: url(../images/icon_7.svg) 50% 50% no-repeat;
                  background-size: contain;
                }
                &.icon_8{
                  background: url(../images/icon_8.svg) 50% 50% no-repeat;
                  background-size: contain;
                }
                &.icon_9{
                  background: url(../images/icon_9.svg) 50% 50% no-repeat;
                  background-size: contain;
                }
                &.icon_10{
                  background: url(../images/icon_10.svg) 50% 50% no-repeat;
                  background-size: contain;
                }
                &.icon_11{
                  background: url(../images/icon_11.svg) 50% 50% no-repeat;
                  background-size: contain;
                }
                &.icon_12{
                  background: url(../images/icon_12.svg) 50% 50% no-repeat;
                  background-size: contain;
                }
              }

              .industries_block_text{
                & p{
                  color: rgba(58, 58, 58, 1);
                  font-family: 'HelveticaNeueCyr';
                  font-weight: 200;
                  text-align: left;
                  margin: 0;
                  @media @mobile {
                    font-size: 18px;
                  }
                  @media @desktop {
                    font-size: 20px;
                  }
                }
              }
            }
          }          
        }
        .nexindustrial_cont{
          width: 100%;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          @media @mobile {
            row-gap: 45px;
          }
          @media @tablet {
            row-gap: 0;
          }

          .nexindustrial_svg{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @media @mobile {
              width: 100%;
            }
            @media @tablet {
              width: 50%;
            }
            @media @desktop {
              width: 33%;
            }
    
            .nexindustrial_block_svg{
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              @media @mobile {
                height: 240px;
              }
              @media @tablet {
                height: 360px;
              }
              & a{
                display: inline-block;
                max-width: 300px;
                width: 100%;
                height: 100%;
                transition: .3s ease-out;
                transform: scale(1);    
    
                &.nexindustrial_svg_bg{
                  width: 60%;
                  height: 100%;
                }
              }
            }
            & h4{
              width: 100%;
              @media @mobile {
                margin: 20px 0;
              }
              @media @tablet {
                margin: 45px 0;
              }
              & a{
                display: table;
                text-align: center;
                margin: 0 auto;
                font-size: 20px;
                color: rgba(33, 33, 33, 1);
                font-family: 'HelveticaNeueCyr';
                font-weight: 300;
                text-transform: uppercase;
                transition: all.3s ease-out;
              }
            }
            .only_arrow{
              margin: 0 auto;
            }
            &:hover{
              .nexindustrial_block_svg{
                & a{
                  transform: scale(1.05);
                }
              }
              & h4{
                & a{
                  color: #f05623;
                }
              }
              .only_arrow{
                .svg_orange{
                  left: 0px;
                }
                .svg_black{
                  left: 55px;
                }
              }
            }
          }
        }
        .product_sec_sert{
          display: flex;
          flex-wrap: wrap;
          @media @mobile {
            row-gap: 60px;
            margin-top: 60px;
            justify-content: center;
            column-gap: 30px;
          }
          @media @desktop-xl {
            row-gap: 80px;
            margin-top: 70px;
            justify-content: space-between;
          }
          .product_sec_sert_block{
            max-width: 392px;
            width: 100%;

            .sert_block{
              width: 100%;
              margin-bottom: 30px;
              box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
              @media @mobile-2-1 {
                height: 554px;
              }
              & img{
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
          .product_sec_sert_block_title{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .product_sec_sert_text{
              width: 240px;

              h3{
                margin: 0;
              }
              .polo{
                width: 100%;
                height: 1px;
                background-color: #CBCBCB;
                margin: 10px 0;
              }
              & p{
                margin: 0;
              }
            }
          }
        }
        .p_bold{
          font-weight: 400;
          line-height: 1.4;
          margin-bottom: 50px;
        }
        .product_vakansii{

          & h2{
            margin-bottom: 30px;
          }

          .product_vakansii_filter{
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            position: relative;
            @media @mobile {
              justify-content: center;
              padding-top: 50px;
              row-gap: 15px;
              column-gap: 30px;
              margin-bottom: 30px;
            }
            @media @tablet {
              justify-content: space-between;
            }
            @media @desktop-xl {
              padding-top: 0;
              row-gap: 0;
              margin-bottom: 60px;
            }

            .filter{
              width: 48px;
              height: 42px;
              background: url(../images/filter.svg) 50% 50% no-repeat;
              background-size: cover;
              @media @mobile {
                position: absolute;
                top: 0;
                left: 0;
              }
              @media @desktop-xl {
                position: relative;
              }
            }

            .filter_exit{
              width: 45px;
              height: 45px;
              border: 1px solid #CAD3DC;
              position: relative;
              cursor: pointer;
              @media @mobile {
                position: absolute;
                top: 0;
                right: 0;
              }
              @media @desktop-xl {
                position: relative;
              }
              &:before,
              &:after{
                content: '';
                display: block;
                width: 20px;
                height: 2px;
                background-color: #CAD3DC;
                position: absolute;
                top: 50%;
                left: 50%;
              }
              &:before{
                transform: translate(-50%, -50%) rotate(45deg);
              }
              &:after{
                transform: translate(-50%, -50%) rotate(-45deg);
              }
            }
            
            .block_v{
              max-width: 395px;
              width: 100%;
              box-shadow: inset 0px 0px 0px 1px #CAD3DC;
              position: relative;
              &:nth-child(1){
                z-index: 5;
              }
              &:nth-child(2){
                z-index: 4;
              }
              &:nth-child(3){
                z-index: 3;
              }
              &:nth-child(4){
                z-index: 2;
              }
              &:nth-child(5){
                z-index: 1;
              }
            }
          }
          .product_vakansii_sec{
            display: flex;
            flex-wrap: wrap;
            row-gap: 30px;

            .product_vakansii_sec_block{
              width: 100%;
              background-color: #EFF1F3;
              @media @tablet {
                padding: 30px 10px 30px;
              }
              @media @mobile {
                padding: 30px;
              }
              @media @desktop-xl {
                padding: 30px 30px 30px 100px;
              }

              .product_vakansii_sec_info{
                margin-bottom: 10px;
                & span{
                  font-size: 17px;
                  color: rgba(152, 157, 163, 1);
                }
              }
              .arr_all{
                &:hover{
                  margin-left: -10px;
                }
              }
            }
            & p{
              & a{
                color: #f05623;
              }
            }
          }
        }
      }
    }
  }
}