// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.kluch {
  background-color: #ffffff;
  position: relative;
  z-index: 2;

  .kluch_bg{
    background-color: #EAEAEA;
    @media @mobile {
      padding: 50px 0 100px 0;
      clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    }
    @media @desktop-xl {
      padding: 100px 0 140px 0;
      clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);
    }
  }
  &__main{

    &__item{
      position: relative;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      @media @mobile {
        width: 100%;
      }
      @media @desktop-xl {
        width: 50%;
        margin-right: 30px;
        float: right;
      }
      @media @desktop-xl-1 {
        margin-right: 100px;
      }
      & h2{
        @media @mobile {
          width: 100%;
          text-align: center;
          margin-bottom: 20px;
        }
        @media @desktop-xl {
          text-align: right;
        }
      }
      .kluch_svg{
        @media @mobile {
          max-width: 500px;
          width: 100%;
          height: ~"calc(-100% / 16 * 9)";
          margin: 0 auto;
          margin-bottom: 30px;
        }
        @media @desktop-xl {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: ~"calc(-90% + 50px)";
          width: 500px;
          height: 280px;
          margin-bottom: 0;
        }
        @media @desktop-xl-1 {
          max-width: 570px;
          width: 100%;
          height: 330px;
          left: ~"calc(-70% + 50px)";
        }
        @media @desktop-xl-2 {
          max-width: 590px;
          width: 100%;
          height: 340px;
        }
        & span{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      & h3{
        width: 100%;
        text-transform: uppercase;
        font-size: 19px;
        color: rgba(101, 101, 101, 1);        
        font-family: 'HelveticaNeueCyr';
        font-weight: 300;
        margin-bottom: 20px;
        @media @mobile {
          text-align: center;
        }
        @media @desktop-xl {
          text-align: right;
        }
      }
      .arrow_next{
        @media @mobile {
          margin: 0 auto;
        }
        @media @desktop-xl {
          margin: 0;
          float: right;
        }
      }
    }
  }
}