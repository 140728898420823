// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.news {
  background-color: #ffffff;
  position: relative;
  @media @mobile {
    padding: 50px 0;
  }
  @media @desktop-xl {
    padding: 100px 0;
  }

  &__main{

    .number_sec{
      top: 650px;
    }

    &__item{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      row-gap: 130px;

      .news_cont{
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        @media @mobile {
          justify-content: center;
          flex-wrap: wrap;
        }
        @media @desktop {
          justify-content: space-between;
          flex-wrap: nowrap;
        }
        &:before{
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background-color: #E4E4E4;
          position: absolute;
          top: -65px;
          right: 0;
        }
        &:nth-child(1){
          &:before{
            display: none;
          }
        }
        .news_cont_img{
          display: flex;
          align-items: center;
          justify-content: center;
          @media @mobile {
            width: auto;
            margin-bottom: 30px;
          }
          @media @desktop {
            margin-right: 45px;
            width: 375px;
            margin-bottom: 0;
          }
          @media @desktop-xl-1 {
            width: 475px;
          }
          @media @desktop-xl-2 {
            width: 575px;
            margin-right: 55px;
          }
          & img{
            width: 100%;
            object-fit: contain;
          }
        }
        .news_cont_text{
          @media @mobile {
            max-width: 100%;
            width: 100%;
          }
          @media @desktop {
            max-width: 790px;
            width: 100%;
          }

          & h3{
            color: rgba(58, 58, 58, 1);
            font-family: 'HelveticaNeueCyr';
            font-weight: 300;
            margin-bottom: 15px;
            @media @mobile {
              font-size: 20px;
            }
            @media @desktop-xl {
              font-size: 30px;
            }
          }
          .date_news{
            color: rgba(90, 90, 90, 1);
            font-family: 'HelveticaNeueCyr';
            font-weight: 200;
            margin-bottom: 15px;
            @media @mobile {
              font-size: 15px;
            }
            @media @desktop-xl {
              font-size: 17px;
            }
          }
          .opis_news{
            color: rgba(58, 58, 58, 1);
            font-family: 'HelveticaNeueCyr';
            font-weight: 200;
            margin-bottom: 45px;
            @media @mobile {
              font-size: 16px;
            }
            @media @desktop-xl {
              font-size: 18px;
            }
          }
          .arr_all{
            &:hover{
              margin-left: -10px;
              padding-right: 50px;
            }
          }
        }
      }
    }
  }
}