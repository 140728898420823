// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.footer {
  position: relative;
  background-color: #fff;

  & > div {
    background-color: #F9F9F9;

    @media @mobile {
      padding: 50px 0;
      clip-path: polygon(75% 2%, 100% 0, 100% 100%, 0 100%, 0 0);
    }
    @media @desktop-xl {
      padding: 100px 0 50px 0;
      clip-path: polygon(75% 8%, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }

  &__main{
    .footer_logo{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 50px;
      @media @mobile {
        column-gap: 10px;
      }
      @media @tablet {
        column-gap: 20px;
      }
      .logo_kmz{
        display: block;
        position: relative;
        background: rgba(241, 86, 35, 1);
        @media @mobile {
          width: 42px;
          height: 30px;
        }
        @media @tablet {
          width: 57px;
          height: 39px;
        }
        &:before{
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: url(../images/logo_kmz.svg) 50% 50% no-repeat;
          background-size: contain;
          @media @mobile {
            width: 30px;
            height: 16px;
          }
          @media @tablet {
            width: 42px;
            height: 20px;
          }
        }
      }
      .logo_vab{
        display: block;
        background: url(../images/logo_vab.svg) 50% 50% no-repeat;
        background-size: contain;
        @media @mobile {
          width: 30px;
          height: 30px;
        }
        @media @tablet {
          width: 44px;
          height: 34px;
        }
      }
    }
    &__item{
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media @mobile {
        flex-wrap: wrap;
        column-gap: 20px;
      }
      @media @tablet {
        flex-wrap: nowrap;
      }
      @media @desktop {
        column-gap: 0;
      }

      .footer_cont{
        display: flex;

        &.footer_cont_1{
          @media @mobile {
            width: 100%;
          }
          @media @tablet {
            width: 220px;
          }
        }
        &.footer_cont_2{
          @media @mobile {
            width: 100%;
          }
          @media @tablet {
            width: 320px;
          }
        }
        &.footer_cont_3{
          @media @mobile {
            width: 100%;
          }
          @media @tablet {
            width: 340px;
          }
        }

        .footer_cont_all{
          //
        }
        .poldl{
          width: 100%;
          margin-bottom: 15px;
          &.marg_15{
            margin-bottom: 15px;
          }
          &.marg_30{
            margin-bottom: 30px;
          }
          &.marg_30_top{
            margin-top: 30px;
          }
          &.marg_15_mob{
            @media @mobile {
              margin-bottom: 15px;
            }
            @media @tablet {
              margin-top: 30px;
            }
          }
        }
        & h3,
        .a_h3{
          font-size: 18px;
          text-transform: uppercase;
          font-family: 'HelveticaNeueCyr';
          font-weight: 300;
          color:rgba(24, 24, 24, 1);
        }
        .a_h3{
          &:hover{
            color: #f05623;
          }
        }
        & ul{
          width: 100%;
          margin-bottom: 15px;
          & li{
            & a{
              font-family: 'HelveticaNeueCyr';
              font-weight: 200;
              color: rgba(73, 73, 73, 1);
              @media @mobile {
                font-size: 16px;
              }
              @media @tablet {
                font-size: 17px;
              }
              @media @desktop-xl-2 {
                font-size: 20px;
              }
              &:hover{
                color: #f05623;
              }
            }
          }
        }
        .end_3{
          font-size: 18px;
          font-family: 'HelveticaNeueCyr';
          font-weight: 200;
          color: rgba(73, 73, 73, 1);
          @media @mobile {
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
          }
          @media @tablet {
            width: auto;
            text-align: left;
            margin-bottom: 30px;
          }
        }
        .footer_cont_ic{
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          height: 40px;
          @media @mobile {
            margin-bottom: 40px;
          }
          &.footer_cont_ic_1{
            width: 190px;
          }
          &.footer_cont_ic_2{
            width: 270px;
          }
        }
      }
    }
    .footer_end{
      display: flex;
      align-items: center;
      @media @mobile {
        flex-wrap: wrap;
        justify-content: center;
      }
      @media @tablet {
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      & p{
        font-size: 14px;
        font-weight: 100;
        color: #898989;
        @media @mobile {
          width: 100%;
          text-align: center;
          margin-bottom: 30px;
        }
        @media @tablet {
          width: auto;
          text-align: left;
          margin-bottom: 0;
        }
      }
      .block_push-k{
        width: 100px;

        & a{
          display: block;
          width: 100%;
          text-align: center;
          text-transform: uppercase;
          font-size: 10.5px;
          font-weight: 100;
          color: #898989;
          &.ua{
            font-size: 10.5px;
          }
          &.ru{
            font-size: 9.5px;
          }
          &.en{
            font-size: 9.5px;
          }
        }
        .logo_push-k{
          width: 100%;
          height: 35px;
          background: url(../images/logo_push-k.svg) 50% 50% no-repeat;
          background-size: contain;
        }
      }
    }
  }

}