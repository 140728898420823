@dir: "../images/";
@dir-fonts: "../fonts/";

@html-size-base: 10px;

@fs: 1.6rem;
@fss: 2rem;
@fsb: 1.6rem;
@h1: 4rem;
@h2: 3rem;
@h3: 2rem;
@h4: 2rem;
@h5: 2rem;
@h6: 2rem;



@lh: 1;

@ff1: 'Roboto', sans-serif;
@ff2: 'PT Sans', sans-serif;

@black: #2b2b2b;
@grey: #2b2b2b;

@text-color: @black;
@text-margin: 0 0 0rem;

@mobile: ~"only screen and (min-width: 320px)";
@mobile-2: ~"only screen and (min-width: 375px)";
@mobile-2-1: ~"only screen and (min-width: 480px)";
@mobile-2-2: ~"only screen and (min-width: 560px)";
@tablet: ~"only screen and (min-width: 768px)";
@desktop: ~"only screen and (min-width: 992px)";
@desktop-xl: ~"only screen and (min-width: 1200px)";
@desktop-xl-1: ~"only screen and (min-width: 1366px)";
@desktop-xl-2: ~"only screen and (min-width: 1600px)";
@desktop-xll: ~"only screen and (min-width: 1768px)";

// ==========>


@font-face {
  src: url('../fonts/HelveticaNeueCyr-UltraLight.otf') format('opentype');
  font-family: 'HelveticaNeueCyr';
  font-weight: 100;
  font-display: swap;
}

@font-face {
  src: url('../fonts/HelveticaNeueCyr-Light.otf') format('opentype');
  font-family: 'HelveticaNeueCyr';
  font-weight: 200;
  font-display: swap;
}

@font-face {
  src: url('../fonts/HelveticaNeueCyr-Roman.otf') format('opentype');
  font-family: 'HelveticaNeueCyr';
  font-weight: 300;
  font-display: swap;
}

@font-face {
  src: url('../fonts/HelveticaNeueCyr-Medium.otf') format('opentype');
  font-family: 'HelveticaNeueCyr';
  font-weight: 400;
  font-display: swap;
}

@font-face {
  src: url('../fonts/Oswald-VariableFont_wght.ttf') format('truetype');
  font-family: 'oswald';
  font-weight: 100;
  font-display: swap;
}
@font-face {
  src: url('../fonts/Oswald-VariableFont_wght.ttf') format('truetype');
  font-family: 'oswald';
  font-weight: 300;
  font-display: swap;
}
@font-face {
  src: url('../fonts/Oswald-VariableFont_wght.ttf') format('truetype');
  font-family: 'oswald';
  font-weight: 400;
  font-display: swap;
}
