// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.services {
  position: relative;
  overflow: hidden;
  z-index: 2;
  @media @mobile {
    padding: 50px 0;
  }
  @media @desktop-xl {
    padding: 100px 0;
  }
  
  &__main{
    width: 100%;

    & h2{
      @media @mobile {
        margin-bottom: 45px;
      }
      @media @desktop-xl {
        margin-bottom: 60px;
      }
      @media @desktop-xl-2 {
        margin-bottom: 110px;
      }
    }

    &__item{
      display: flex;
      flex-wrap: wrap;
      @media @mobile {
        justify-content: center;
        row-gap: 60px;
      }
      @media @tablet {
        justify-content: flex-start;
        row-gap: 40px;
      }

      .services_block{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media @mobile {
          width: 300px;
        }
        @media @tablet {
          width: 100%;
          &:nth-child(1){
            margin-left: 7vw;
          }
          &:nth-child(2){
            margin-left: 20vw;
          }
          &:nth-child(3){
            margin-left: 35vw;
          }
        }

        .services_ic{
          display: flex;
          align-items: center;
          justify-content: center;

          .span_ic{
            display: block;

            &.ic_1_m{
              width: 88px;
              height: 60px;
              flex: 0 0 88px;
            }

            &.ic_2_m{
              width: 88px;
              height: 60px;
              flex: 0 0 88px;
            }

            &.ic_3_m{
              width: 79px;
              height: 99px;
              flex: 0 0 79px;
            }
          }
        }
        .services_text{
          position: relative;
          @media @mobile {
            margin-left: 10px;
          }
          @media @tablet {
            margin-left: 30px;
          }

          & h3{
            color: rgba(49, 49, 49, 1);
            font-family: 'HelveticaNeueCyr';
            font-weight: 200;
            margin-bottom: 10px;
            transition: .3s ease-out;
            @media @mobile {
              font-size: 18px;
            }
            @media @tablet {
              font-size: 24px;
            }
            @media @desktop-xl {
              font-size: 30px;
            }
            @media @desktop-xl-2 {
              font-size: 35px;
            }
          }

          & p{
            color: rgba(129, 129, 129, 1);
            font-family: 'HelveticaNeueCyr';
            font-weight: 200;
            text-transform: uppercase;
            transition: .3s ease-out;
            transition-delay: .15s;
            @media @mobile {
              font-size: 16px;
            }
            @media @desktop-xl {
              font-size: 18px;
            }
            @media @desktop-xl-2 {
              font-size: 20px;
            }
          }
          .only_arrow{
            position: absolute;
            left: 0;
            bottom: -30px;
          }
          &:hover{
            & h3{
            color: #f05623;
            }
            & p{
              color: #f05623;
            }
            .only_arrow{
              .svg_orange{
                left: 0px;
              }
              .svg_black{
                left: 55px;
              }
            }
          }
        }
      }
    }
  }
}