.vertical-container {
	-webkit-transform-style: preserve-3d;
  	-moz-transform-style: preserve-3d;
  	transform-style: preserve-3d;
}
.vertical-inner {
	position: relative;
  	top: 50% !important;
  	transform: translateY(-50%);
  	z-index: 99;
}