// @media @mobile {
// }
// @media @tablet {
// }
// @media @desktop {
// }
// @media @desktop-xl {
// }
// @media @desktop-xl-2 {
// }
// @media @desktop-xll {
// }

.header{
  position: relative;
  background-color: #fff;
  z-index: 0;

  @media @mobile {
    height: 90vh;
    margin-bottom: 10vh;
  }
  @media @tablet {
    height: 100vh;
    margin-bottom: 0;
  }
  
  .header_bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    &:before{
      content: '';
      display: block;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background: rgba(3, 3, 3, 0.6);
    }
    
    & video{
      width: 100%;
      height: 100vh;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
      
    & img{
      width: 100%;
      height: 100vh;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
  .header_mask{
    width: 100%;
    height: 100vh;
    background-color: #fff;
    position: absolute;
    transition: 1.5s ease-out;
    z-index: 2;
    @media @mobile {
      clip-path: polygon(100% 100%, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    @media @desktop-xl {
      clip-path: polygon(100% 100%, 100% 0, 100% 100%, 0 100%, 100% 100%);
    }
  }
  .container{
    position: relative;
    z-index: 3;
  }

  &__main{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    padding-bottom: 100px;
    @media @mobile {
      height: 90vh;
    }
    @media @tablet {
      height: 100vh;
    }
    
    .head_bot{
      position: absolute;
      @media @mobile {
        right: 30px;
        bottom: 40px;
      }
      @media @mobile-2-2 {
        bottom: 0;
      }
      @media @tablet {
        bottom: 33px;
      }
      @media @desktop-xl {
      }
      @media @desktop-xl-2 {
        bottom: 53px;
      }
      @media @desktop-xll {
        right: 15px;
      }

      .head_bot_mask{
        text-transform: uppercase;
        font-family: 'HelveticaNeueCyr';
        font-weight: 200;
        color: rgba(240, 86, 35, 1);
        clip-path: polygon(100% 100%, 100% 0, 100% 100%, 0% 100%);
        @media @mobile {
          font-size: 14px;
        }
        @media @tablet {
          font-size: 18px;
        }
        & br{
          @media @mobile {
            display: block;
          }
          @media @tablet {
            display: none;
          }
        }
      }
      .head_bot_kvad{
        width: 4px;
        height: 4px;
        position: absolute;
        right: -22px;
        bottom: 100px;
        background-color: rgba(240, 86, 35, 1);
        opacity: 0;
      }
    }
  }
  &.anime{
    .header_mask{
      @media @mobile {
        clip-path: polygon(100% 67%, 100% 0, 100% 100%, 0 100%, 0 80%);
      }
      @media @desktop-xl {
        clip-path: polygon(100% 77%, 100% 0, 100% 100%, 0 100%, 100% 77%);
      }
    }
    
    .head_load_1,
    .head_load_2{
      padding-top: 0;
      opacity: 1;
    }
    .krohi_load_2_2{
      padding-top: 0;
      opacity: 1;
      transition: .4s ease-out;
    }
    .head_bot_kvad{
      width: 4px;
      height: 4px;
      position: absolute;
      right: -22px;
      bottom: 100px;
      opacity: 0;
      @media @mobile {
        animation: head_bot_kvad_mob 2s ease-out;
        animation-fill-mode: forwards;
        animation-delay: 1.5s;
      }
      @media @tablet {
        animation: head_bot_kvad 2s ease-out;
        animation-fill-mode: forwards;
        animation-delay: 1.5s;
      }
    }
    @keyframes head_bot_kvad {
      0% {
        width: 4px;
        height: 4px;
        right: -22px;
        bottom: 100px;
        opacity: 0;
      }
      1% {
        opacity: 1;
      }
      23.3% {
        width: 4px;
        height: 4px;
        right: -22px;
        bottom: 4px;
      }
      33.3% {
        width: 18px;
        height: 18px;
        right: -22px;
        bottom: 4px;
      }
      90% {
        width: 18px;
        height: 18px;
        right: ~"calc(100% + 5px)";
        bottom: 4px;
      }
      99% {
        width: 4px;
        height: 4px;
        right: ~"calc(100% + 19px)";
        bottom: 4px;
        opacity: 1;
      }
      100% {
        width: 4px;
        height: 4px;
        right: ~"calc(100% + 19px)";
        bottom: 4px;
        opacity: 0;
      }
    }
    @keyframes head_bot_kvad_mob {
      0% {
        width: 4px;
        height: 4px;
        right: -22px;
        bottom: 100px;
        opacity: 0;
      }
      1% {
        opacity: 1;
      }
      23.3% {
        width: 4px;
        height: 4px;
        right: -22px;
        bottom: 4px;
      }
      33.3% {
        width: 34px;
        height: 34px;
        right: -22px;
        bottom: 4px;
      }
      90% {
        width: 34px;
        height: 34px;
        right: ~"calc(100% + 5px)";
        bottom: 4px;
      }
      99% {
        width: 4px;
        height: 4px;
        right: ~"calc(100% + 19px)";
        bottom: 4px;
        opacity: 1;
      }
      100% {
        width: 4px;
        height: 4px;
        right: ~"calc(100% + 19px)";
        bottom: 4px;
        opacity: 0;
      }
    }
    .head_bot_mask{
      animation: head_bot_mask 2s ease-out;
      animation-fill-mode: forwards;
      animation-delay: 1.5s;
    }
    @keyframes head_bot_mask {
      0% {
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
      }
      33.3% {
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
      }
      90% {
        clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
      }
      100% {
        clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
      }
    }
  }
  &.header_min{
    margin-bottom: 0;
    @media @mobile {
      height: 80vh;
    }
    @media @tablet {
      height: 386px;
    }
    &:before{
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      left: 0;
      bottom: -1px;
      z-index: 2;
    }
    .header_bg{
      @media @mobile {
        height: 80vh;
      }
      @media @tablet {
        height: 386px;
      }
      & img{
        @media @mobile {
          height: 80vh;
        }
        @media @tablet {
          height: 386px;
        }
      }
    }
    .container{
      height: 100%;
    }
    .header_mask{
      height: 100%;
    }
    .header__main{
      @media @mobile {
        padding: 0;
        height: 100%;
      }
      @media @desktop {
        padding: 0 0 0 100px;
      }
      @media @desktop-xl {
        padding: 0 0 0 200px;
      }
      .head_load_1{
        height: auto;
        padding-top: 100px;
        opacity: 0;
        transition: .4s ease-out;
      }
      & h1{
        @media @mobile {
          font-size: 36px;
          padding-left: 13px;
        }
        @media @tablet {
          font-size: 45px;
        }
        @media @desktop-xl {
          padding-left: 13px;
        }
      }
    }
    &.anime_pod{
      .header_mask{
        @media @mobile {
          clip-path: polygon(100% 100%, 100% 0, 100% 90%, 0 100%, 0 100%);
        }
        @media @desktop-xl {
          clip-path: polygon(100% 77%, 100% 0, 100% 100%, 0 100%, 100% 77%);
        }
      }
    
      .head_load_1,
      .head_load_2{
        padding-top: 0;
        opacity: 1;
      }
      .krohi_load_2_2{
        padding-top: 0;
        opacity: 1;
      }
    }
  }
  
}

.head_load_1{
  width: 100%;
  text-transform: uppercase;
  color: #fff;
  font-family: 'HelveticaNeueCyr';
  font-weight: 300;
  display: inline-block;
  padding-top: 100px;
  opacity: 0;
  transition: .4s ease-out;
  @media @mobile {
    font-size: 36px;
    width: ~'calc(100% - 24px)';
    height: 51px;
  }
  @media @tablet {
    width: 100%;
    font-size: 45px;
  }
  @media @desktop-xl {
    font-size: 55px;
    height: 78px;
    margin-bottom: 10px;
  }
  @media @desktop-xl-1 {
    font-size: 65px;
    height: 92px;
  }
  @media @desktop-xl-2 {
    font-size: 75px;
    height: 107px;
  }
  & span{
    display: table;
  }
}
.head_load_2{
  font-size: 30px;
  color: #fff;
  font-family: 'HelveticaNeueCyr';
  font-weight: 100;
  letter-spacing: 1px;
  padding-top: 100px;
  opacity: 0;
  transition: .4s ease-out;
  @media @mobile {
    font-size: 18px;
    width: ~'calc(100% - 24px)';
    height: 42px;
  }
  @media @tablet {
    width: 100%;
  }
  @media @desktop-xl {
    font-size: 20px;
    height: 48px;
  }
  @media @desktop-xl-1 {
    font-size: 24px;
    height: 56px;
  }
  @media @desktop-xl-2 {
    font-size: 30px;
    height: 72px;
  }
  & span{
    display: table;
  }
}
.krohi_load_2_2{
  display: flex;
  justify-content: flex-start;
  opacity: 0;
  transition: .4s ease-out;
  @media @mobile {
    flex-wrap: wrap;
    width: ~'calc(100% - 20px)';
    padding: 5px 0;
  }
  @media @desktop-xl {
    flex-wrap: nowrap;
    max-width: 890px;
    width: 100%;
    height: 32px;
    padding: 0;
  }
  & a,
  & span{
    height: 22px;
    color: #fff;
    font-family: 'HelveticaNeueCyr';
    font-weight: 100;
    letter-spacing: 1.6px;
    transition: .4s ease-out;
    @media @mobile {
      font-size: 14px;
    }
    @media @tablet {
      font-size: 16px;
    }
  }
  & span{
    padding: 0 0 0 15px;
    @media @desktop-xl {
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .a_hover_k{
    color: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    @media @mobile {
      padding: 0 15px 0 15px;
      &:before{
        content: '>';
        display: block;
        position: absolute;
        top: 0;
        right: -7px;
        color: #fff;
        font-family: 'HelveticaNeueCyr';
        font-weight: 100;
        letter-spacing: 1.6px;
        transition: .4s ease-out;
        opacity: 1;
      }
    }
    @media @desktop-xl {
      padding: 0 0 0 15px;
      white-space: nowrap;

      &:nth-child(1){
        &:before{
          content: '>';
          display: block;
          position: absolute;
          top: 0;
          left: -15px;
          right: auto;
          color: #fff;
          font-family: 'HelveticaNeueCyr';
          font-weight: 100;
          letter-spacing: 1.6px;
          transition: .4s ease-out;
          opacity: 0;
        }
      }
      &:before{
        content: '>';
        display: block;
        position: relative;
        margin-right: 15px;
        right: 0;
        color: #fff;
        font-family: 'HelveticaNeueCyr';
        font-weight: 100;
        letter-spacing: 1.6px;
        transition: .4s ease-out;
        opacity: 1;
      }
      &:nth-last-child(2):after{
        content: '>';
        display: block;
        position: relative;
        margin-left: 10px;
        top: 0;
        color: #fff;
        font-family: 'HelveticaNeueCyr';
        font-weight: 100;
        letter-spacing: 1.6px;
        transition: .4s ease-out;
        opacity: 1;
      }
    }
    &:hover{
      color: #f05623;
      @media @mobile {
        &:before{
          color: #f05623;
        }
        &:after{
          color: #f05623;
        }
      }
      
      @media @desktop-xl {
        &:before{
          margin-right: 5px;
          margin-left: 10px;
          top: 1px;
          transform:rotate(180deg);
          opacity: 1;
        }
        &:after{
          margin-left: 5px;
          opacity: 1;
          margin-right: 5px;
        }

        & + .a_hover_k:before {
          margin-left: -10px;
          margin-right: 25px;
          color: #f05623;
          opacity: 1;
        }
      }
    }
  }
  & span{
    display: table;
  }
}

.top_menu .head-title-block-top {
  display: none;
}
@media (min-width: 1200px) {
  .head-title-block {
    position: sticky;
    top: 16px;
    transition: .4s ease-out;
  }

  .top_menu .head-title-block-top {
    display: block;
    width: 800px !important;
    position: relative;
    padding-left: 30px;
  
    .head_load_1 {
      transition: none !important;
      opacity: 0;
    }
  
    .krohi_load_2_2 {
      position: absolute;
      left: 30px;
      bottom: 0;
      opacity: 0;
      visibility: hidden;
    }
  
  } 
  
  .head-title-block-top {
    margin-top: -4px !important;

    .head_load_1 {
      height: auto !important;
      padding-top:0 !important;
      margin-bottom: 0 !important;
      padding-left: 13px !important;
      font-size: 30px !important;
      margin-top: 6px;
    }
    .krohi_load_2_2 {
      height: 20px !important;
      transition: .4s ease-out;
    }
  }
  .head-title-block.head-title-block-top.topscrolled {
    opacity: 0 !important;
  }
  .head-title-block.head-title-block-top.topscrolled .head_load_1{
    opacity: 0 !important;
    transition: none !important;
  }
  .top_menu.topscrolled .head-title-block-top {
    .head_load_1,
    .krohi_load_2,
    .krohi_load_2_2 {
      opacity: 1;
    }
  }
}
@media only screen and (min-width: 1366px) {
  .top_menu .head-title-block-top {
    padding-left: 80px;
  }
}
@media only screen and (min-width: 1600px) {
  .top_menu .head-title-block-top {
    padding-left: 29px;
  }
}